import MasterList from '@/views/master/List.vue'
import ListElectricCompany from "@/views/master/electricCompany/ListElectricCompany.vue";
import AgencyList from "@/views/master/configRequire/AgencyList.vue"
import CompanyList from "@/views/master/configRequire/CompanyList.vue"
import ConfigRequireConfig from "@/views/master/configRequire/Config.vue"
import store from "@/stores/_loader";

const accessRole = store.state.accessRole.adminRole
export const MasterRoute = [
    {
        path: "/master/list",
        name: "master.list",
        component: MasterList,
        meta: {
            menuKey: "master",
            accessRole
        },
        children: [
            {
                name: 'electric_company',
                path: 'm_electric_company',
                component: ListElectricCompany
            },
            {
                name: 'config_require',
                path: 'config-require-agency-list',
                component: AgencyList
            },
            {
                name: 'config_require_company',
                path: 'config-require/:agencyId/company-list',
                component: CompanyList
            },
            {
                name: 'config_require.edit',
                path: 'config-require/:agencyId/:companyId/edit',
                component: ConfigRequireConfig
            }
        ]
    }
]
