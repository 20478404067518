<template>
  <VueApexCharts class="vue-chart-company" type="bar" height="200" with="250" :options="chartOptions"
                 :series="series"></VueApexCharts>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";
import {defineComponent} from "vue";

export default defineComponent({
  // name: "BarChart",
  components: {
    VueApexCharts,
  },
  props: ['lable'],
  data() {
    return {
      series: [{
        name: '',
        data: this.lable
      }],
      chartOptions: {
        colors: ['#00CCFF'],
        chart: {
          dropShadow: {
            enabled: true,
            enabledOnSeries: undefined,
            top: 2,
            left: 3500,
            blur: 3,
            color: '#000',
            opacity: 0.35
          },
          type: 'bar',
          height: 550,
          stacked: true,
          marginLeft: 500,
          width: 350,
          background: '#fff'

        },
        plotOptions: {
          bar: {
            columnWidth: '45%',
            offsetX: 50,
          }
        },
        subtitle: {
          text: undefined,
          align: 'left',
          margin: 60,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: '12px',
            fontWeight: 'normal',
            color: '#9699a2'
          },
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
          offsetX: 40,
        },
        dataLabels: {
          style: {
            fontSize: '0px',
            fontWeight: 'bold',
          }
        },
          tooltip: {
              y: {
                  formatter: function (val) {
                      return val.toLocaleString()
                  }
              },
          },
        stroke: {
          width: -5,
          colors: ['#fff']
        },
        xaxis: {
          categories: [''],
          // categories: ["施設A", "施設B", "施設C", "Text label", "Text label", "Text label", "Text label"],
          fontsize: 0,
          labels: {

            style: {
              fontSize: '0px'
            }
          }
        },

        fill: {
          opacity: 1
        },

        yaxis: {
          title: {
            text: undefined
          },
          categories: [
            [''],
          ],
          labels: {
            style: {
              fontSize: '0px'
            }
          },
        },

      }


    }
  },
})
</script>
<style>
.vue-chart-company .apexcharts-toolbar {
  display: none !important;
}

.vue-chart-company .apexcharts-svg {
  margin-left: -12px;
  margin-top: 10px;
  background: unset !important;
}
</style>
