import {authApi} from "../model/authApi";

export default {
    namespaced: true,
    state() {
        return {
            role: null,
        }
    },
    getters: {
        getRole(state) {
            return state.role
        }
    },
    actions: {
        async setRole(state) {
            let role = await authApi.get(`/api/auth/role`)
            state.state.role = role[0]
            return state.state.role
        },

        removeRole(state) {
            state.state.role = null
        },

        checkRole(state, data) {
            let check = true
            const user = data.user
            const resData = data.data
            if (resData.facility_id !== null && user.facility_id !== null && resData.facility_id !== undefined && resData.facility_id !== user.facility_id) {
                check = false
            }

            if (resData.company_id !== null && user.company_id !== null && resData.company_id !== undefined && resData.company_id !== user.company_id) {
                check = false
            }

            if (resData.agency_id !== null && user.agency_id !== null && resData.agency_id !== undefined && resData.agency_id !== user.agency_id) {
                check = false
            }
            return check
        }
    }
}
