<template>
  <a-spin tip="Uploading..." :spinning="isLoading">
    <div class="p-4">
      <a-col class="text-end">
        <a-button
            type="primary"
            primary
            @click="visible = !visible">
          <plus-square-outlined/>
          行追加
        </a-button>
        <a-modal v-model:visible="visible" title="Upload file" @ok="uploadFile" @cancel="$refs.upload_file.value = ''">
          <a-form-item>
            <input type="file" ref="upload_file" @change="selectFile" hidden="hidden" accept=".xls,.xlsx,.csv"/>
            <a-button :disabled="isLoading" @click="$refs.upload_file.click()">
              <UploadOutlined/>
              Upload
            </a-button>&ensp;
            <span v-for="item in file">
                            {{ item.name }}
                        </span>
          </a-form-item>
        </a-modal>
      </a-col>
      <a-table
          :loading="isLoading"
          :dataSource="dataSource"
          :columns="tableColumns"
          :pagination="false"
          :scroll="{ y: screenY }">
        <template #bodyCell="{column, text, record}">
          <template v-if="column.dataIndex === 'action'">
            <span v-if="record.status === 1">Using</span>
            <a-button v-else @click="activeList(record.id)">use list</a-button>
          </template>
        </template>
      </a-table>
    </div>
  </a-spin>
</template>
<script>
import {
  PlusSquareOutlined,
  UploadOutlined,
  ExclamationCircleOutlined
} from "@ant-design/icons-vue";
import {MasterModel} from "@/model/master";
import {message} from "ant-design-vue";
import {createVNode} from 'vue';
import {Modal} from 'ant-design-vue';

export default {
  components: {
    UploadOutlined,
    PlusSquareOutlined
  },
  data() {
    return {
      isLoading: false,
      dataSource: [],
      tableColumns: [
        {
          title: 'name',
          dataIndex: 'name'
        },
        {
          title: 'action',
          dataIndex: 'action'
        }
      ],
      visible: false,
      file: null,
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    async getList() {
      this.dataSource = await MasterModel.getElectricCompanyList()
    },

    selectFile(e) {
      this.file = e.target.files
    },
    uploadFile() {
      if (this.file && this.file.length > 0) {
        this.isLoading = true
        MasterModel.uploadElectricCompanyFile(this.file)
            .then(res => {
              if (res.success) {
                message.success(res.message)
              } else {
                  if (res.response.status === 413){
                      message.error("1ファイルあたりの最大アップロードサイズは50MBまでです。")
                      this.isLoading = false
                  }else {
                      message.error(res.message)
                  }
              }
              this.getList()
              this.isLoading = false
              this.visible = false
              this.file = null
            }).catch(error => {
                if (error.response && error.response.status === 413) {
                    message.error("1ファイルあたりの最大アップロードサイズは50MBまでです。")
                }
            })
      }
    },

    activeList(id) {
      this.isLoading = true
      MasterModel.activeElectricList(id)
          .then(res => {
            if (res.success) {
              message.success(res.message)
            } else {
              message.error(res.message)
            }
            this.getList()
            this.isLoading = false
          })
    }
  }
}
</script>
