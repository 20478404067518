<template>
  <Chart class="row"
         :size="{ width, height: 500 }"
         :data="this.data"
         :margin="margin"
         :direction="direction"
         :axis="axis">
    <template #layers>
      <Grid strokeDasharray="2,2"/>
      <Line :dataKeys="['年', '企業数'] " :lineStyle="{ stroke: '#5B8FF9' }"/>
      <Line :dataKeys="['年', 'アカウント数']" :lineStyle="{ stroke: '#5AD8A6' }"/>
    </template>

    <template #widgets class="row">
      <Tooltip
          borderColor="#48CAE4"
          :config="{
          年: { hide: true },
          企業数: { color: '#0077b6' },
          アカウント数: { color: 'red' },
          inc: { hide: true }
        }"
      />
    </template>
  </Chart>
</template>

<script lang="ts">
import {defineComponent, ref} from 'vue'
import {Chart, Grid, Line, Tooltip} from 'vue3-charts'

export default defineComponent({
  components: {Chart, Grid, Line, Tooltip},
  props: ['lable'],
  setup() {
    const direction = ref('horizontal')
    const margin = ref({
      left: 0,
      top: 20,
      right: 20,
      bottom: 0
    })

    const axis = ref({
      primary: {
        type: 'band',
        format: (val: string) => {
          return val
        }
      },
      secondary: {
        domain: ['dataMin', 'dataMax'],
        type: 'linear',
        ticks: 8
      }
    })

    return {direction, margin, axis, "width": "1500", data: ref([]),}
  },
  async mounted() {
    this.data = this.lable.map(val => {
      return {
        年: val.year,
        企業数: val.companies,
        アカウント数: val.accounts,
      }
    });
  }
})
</script>
