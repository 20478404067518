<template>
  <div class="d-flex justify-content-between">
    <div class="screen-title">建屋詳細</div>
  </div>
  <div class="content">
    <a-form :label-col="labelCol">
      <a-row :gutter="24">
        <a-col class="px-3" :span="12">
          <a-col :span="24" v-if="this.$store.state.role.role === '管理者'">
            <a-form-item
                :rules="[{ required:true}]"
                label="代理店">
              <a-select
                  v-model:value="building.agency_id"
                  show-search
                  :loading="isLoading"
                  :fieldNames="{ label: 'name', value: 'id' }"
                  @change="filterCompanyByAgency"
                  :options="agencies"/>
              <span class="text-danger" v-if="isInvalid('facility_id')">
                                {{ invalidMessages('facility_id')[0] }}
                            </span>
            </a-form-item>
          </a-col>

          <a-col :span="24"
                 v-if="!this.$store.state.role.role.includes('企業') && !this.$store.state.role.role.includes('施設')">
            <a-form-item
                :rules="[{ required:true}]"
                label="企業">
              <a-select
                  v-model:value="building.company_id"
                  show-search
                  :loading="isLoading"
                  :fieldNames="{ label: 'name', value: 'id' }"
                  @change="filterSelectFacilityByCompany"
                  :options="companies"/>
              <span class="text-danger" v-if="isInvalid('facility_id')">
                                {{ invalidMessages('facility_id')[0] }}
                            </span>
            </a-form-item>
          </a-col>

          <a-col :span="24" v-if="!this.$store.state.role.role.includes('施設')">
            <a-form-item
                :rules="[{ required:true}]"
                label="施設">
              <a-select
                  v-model:value="building.facility_id"
                  show-search
                  :loading="isLoading"
                  :fieldNames="{ label: 'name', value: 'id' }"
                  :options="facilities"/>
              <span class="text-danger" v-if="isInvalid('facility_id')">
                                {{ invalidMessages('facility_id')[0] }}
                            </span>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item
                :rules="[{ required:true}]"
                label="建屋名">
              <a-input
                  v-model:value="building.name"
                  :class="[isInvalid('name') ? 'border-danger' : '']"
                  :disabled="isLoading"/>
              <span class="text-danger" v-if="isInvalid('name')">
                                    {{ invalidMessages('name')[0] }}
                                </span>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item
                label="建屋区分">
              <a-select
                  placeholder="建屋区分"
                  v-model:value="building_type_value"
                  :options="building_type"
                  :disabled="isLoading"
                  @change="building.building_type_id = parseInt(building_type_value)"/>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item
                label="構造区分">
              <a-select
                  placeholder="構造区分"
                  v-model:value="construction_value"
                  :options="construction"
                  :disabled="isLoading"
                  @change="building.building_construction_type_id = parseInt(construction_value)"/>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item label="区分:">
              <a-radio-group v-model:value="direction" :disabled="isLoading"
                             @change="building.is_direct_profit = parseInt(direction)">
                <a-radio value=0>間接部門</a-radio>
                <a-radio value=1>直接部門</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
        </a-col>

        <a-col class="px-3" :span="12">
          <a-col :span="24">
            <a-form-item
                :rules="[{ required:true}]"
                label="階数">
              <a-input
                  v-model:value="building.floor_count"
                  :class="[isInvalid('floor_count') ? 'border-danger' : '']"
                  :disabled="isLoading"/>
              <span class="text-danger" v-if="isInvalid('floor_count')">
                                    {{ invalidMessages('floor_count')[0] }}
                                </span>
            </a-form-item>
          </a-col>

          <a-col :span="24" class="d-flex" style="margin-left: 5.5px">
            <a-form-item
                class="w-100"
                label="建築面積">
              <a-input
                  v-model:value="building.build_area_square"
                  :class="[isInvalid('build_area_square') ? 'border-danger' : '']"/>
              <span class="text-danger" v-if="isInvalid('build_area_square')">
                                    {{ invalidMessages('build_area_square')[0] }}
                                </span>
            </a-form-item>
            <span class="px-1">m2</span>
          </a-col>

          <a-col :span="24" class="d-flex" style="margin-left: 5.5px">
            <a-form-item
                class="w-100"
                label="敷地面積">
              <a-input
                  v-model:value="building.area_square"
                  :class="[isInvalid('area_square') ? 'border-danger' : '']"/>
              <span class="text-danger" v-if="isInvalid('area_square')">
                                    {{ invalidMessages('area_square')[0] }}
                                </span>
            </a-form-item>
            <span class="px-1">m2</span>
          </a-col>

          <a-col :span="24">
            <a-form-item label="メモ">
              <a-textarea v-model:value="building.memo" :disabled="isLoading"
                          :class="[isInvalid('memo') ? 'border-danger' : '']"/>
              <span class="text-danger" v-if="isInvalid('memo')">
                                {{ invalidMessages('memo')[0] }}
                            </span>
            </a-form-item>
          </a-col>
        </a-col>
      </a-row>

      <div class="text-center">
        <a-button class="mx-2" @click="$router.push({ name: 'building.list' })" :loading="isLoading" danger>
          キャンセル
        </a-button>
        <a-button class="mx-2" type="primary" :loading="isLoading" @click="this.visible = true" danger>削除
        </a-button>
        <a-button class="mx-2" type="primary" :loading="isLoading" @click="update">更新</a-button>
      </div>

    </a-form>

    <a-modal
        v-model:visible="visible"
        title="操作確認"
        ok-text="はい"
        cancel-text="いいえ"
        @ok="deleteBuilding"
        @cancel="this.visible = false"
        :bodyStyle="{ borderRadius: '5px' }"
        :width="400"
        :okButtonProps="{ type: 'danger' }"
    >
      <p>
        <ExclamationCircleOutlined class="larger-icon"/>
        削除してもよろしいでしょうか?
      </p>
    </a-modal>
  </div>
</template>

<script>
import {
  CheckOutlined,
  CloseOutlined, ExclamationCircleOutlined
} from '@ant-design/icons-vue'
import {BuildingModel} from "@/model/building";
import {FacilityModel} from "@/model/facility";
import {agency} from "@/model/agency";
import {CompanyModel} from "@/model/company";
import {message} from 'ant-design-vue';
import {Confirm} from "notiflix";

export default {
  components: {
    ExclamationCircleOutlined,
    CheckOutlined,
    CloseOutlined
  },
  data() {
    return {
      status: false,
      isLoading: true,
      visible: false,
      labelCol: {span: 4},
      direction: null,
      building_type_value: null,
      construction_value: null,
      building: {},
      building_type: [],
      construction: [],
      facilities: [],
      companies: [],
      agencies: [],
    }
  },
  async mounted() {
    let buildingId = this.$router.currentRoute.value.params.buildingId
    let user = await this.$store.dispatch('auth/me')

    BuildingModel.listBuildingType()
        .then(res => {
          Object.entries(res).forEach(entry => {
            const [key, val] = entry;
            this.building_type.push({
              label: val,
              value: key
            })
          })
        })

    BuildingModel.listConstruction()
        .then(res => {
          Object.entries(res).forEach(entry => {
            const [key, val] = entry;
            this.construction.push({
              label: val,
              value: key
            })
          })
        })

    BuildingModel.detail(buildingId)
        .then(async resData => {
          if (resData && resData.success === false) {
            message.error(resData.message)
            this.$router.push({name: 'building.list'})
          } else {
            let checkRole = await this.$store.dispatch('role/checkRole', {
              user: user,
              data: resData.data
            })
            if (this.$store.state.role.role === '管理者' || checkRole) {
              this.building = resData.data
              this.direction = `${this.building.is_direct_profit}`
              this.status = this.building.status === 1
              if (this.building.building_type_id) this.building_type_value = `${this.building.building_type_id}`
              if (this.building.building_construction_type_id) this.construction_value = `${this.building.building_construction_type_id}`
            } else {
              message.error(`アクセス権限がありません。`)
              this.$router.push({name: 'building.list'})
            }
          }
          this.isLoading = false
        })

    if (this.$store.state.role.role === '管理者') {
      this.agencies = await agency.getAllAgencyNames()
      this.companies = await CompanyModel.getAllCompanyNames()
      this.facilities = await FacilityModel.getAllFacilityNames()
    } else {
      this.companies = await CompanyModel.getCompanyByAgency(user.agency_id)
      this.facilities = await FacilityModel.getFacilityByParent(user.agency_id, user.company_id)
    }
  },
  methods: {
    update() {
      this.clearError()
      this.isLoading = true
      BuildingModel.update(this.building.id, this.building)
          .then(res => {
            if (res?.response?.status === 422) {
              this.tryGetErrorResponse(res)
            } else {
              message.success(res.message)
              this.$router.push({name: 'building.list'})
            }
            this.isLoading = false
          })
    },

    deleteBuilding() {
      this.isLoading = true
      BuildingModel.deleteBuilding(this.building.id)
          .then(res => {
            if (res && res.status === true) {
              message.success('削除されました。')
              this.$router.push({name: 'building.list'})
            } else {
              message.error('に削除されました。')
            }
            this.isLoading = false
          })

    },

    async filterCompanyByAgency() {
      this.building.company_id = null
      this.building.facility_id = null
      this.companies = [...await CompanyModel.getCompanyByAgency(this.building.agency_id ?? 0)]
      this.facilities = [...await FacilityModel.getFacilityByParent(this.building.agency_id ?? 0, this.building.company_id ?? 0)]
    },

    async filterSelectFacilityByCompany() {
      this.building.facility_id = null
      this.facilities = [...await FacilityModel.getFacilityByParent(this.building.agency_id ?? 0, this.building.company_id ?? 0)]
    }
  }
}
</script>
<style>
.ant-radio-wrapper {
  padding-left: 30px !important;
}

.label {
  padding: 10px
}
</style>
