export default {
    namespaced: true,
    state() {
        return {
            adminRole : ['管理者'],
            agencyRole : ['管理者','代理店管理者', '代理店担当者'],
            companyRole : ['管理者', '企業管理者', '企業担当者'],
            facilityRole :['管理者', '企業管理者', '企業担当者'],
            buildingRole : ['管理者', '企業管理者', '企業担当者', '施設担当者'],
            userManagementRole : ['管理者', '代理店管理者', '企業管理者'],
            maintainCreateOrEditRole : ['代理店管理者', '代理店担当者', '企業管理者', '企業担当者', '施設担当者']
        }
    },
}
