<template>
  <a-layout-header class="bg-light p-0 border-bottom d-flex justify-content-between">
    <div></div>
    <Info/>
  </a-layout-header>
  <a-layout-content class="p-4 content-admin">
    <a-row justify="space-around">
      <a-col v-if="this.role === '管理者'" :span="5">
        <div class="" id="agency" @click="$router.push({ name: 'agency.list' })">
          <div class="title ps-4 pt-3">
            代理店数
          </div>
          <div class="quantity ps-4 pt-2">
            {{ data.count_agencies }}
          </div>
        </div>
      </a-col>
      <a-col :span="5">
        <div class="" id="company" @click="$router.push({ name: 'company.list' })">
          <div class="title ps-4 pt-3">
            企業数
          </div>
          <div class="quantity ps-4 pt-2">
            {{ data.count_companies }}
          </div>
        </div>
      </a-col>
      <a-col :span="5">
        <div class="" id="account" @click="$router.push({ name: 'user.list' })">
          <div class="title ps-4 pt-3">
            アカウント数
          </div>
          <div class="quantity ps-4 pt-2">
            {{ data.count_users }}
          </div>
        </div>
      </a-col>
      <a-col :span="5">
        <div class="" id="new_company" @click="$router.push({ name: 'company.list' })">
          <div class="title ps-4 pt-3">
            今月新企業
          </div>
          <div class="quantity ps-4 pt-2">
            {{ data.count_new_companies }}
          </div>
        </div>
      </a-col>
    </a-row>
    <div class="content mt-5">
      <div class="row">
        <div class="col-md-7 col-xl-4 fs-4 ms-3">
          新規登録数
        </div>
      </div>
      <div class="row">
        <div class="col-md-7 col-xl-4 fs-8 ms-3">
          新規登録集計
        </div>
      </div>
      <div class=" fs-8 ms-3 mt-2 d-flex justify-content-between" style="width: 200px;">
        <div style="color:#5B8FF9; margin-top: 6px">
          <minus-outlined class="ttt"/>
        </div>
        <div>企業数</div>
        <div class="ms-3 " style="color:#5AD8A6; margin-top: 5px;">
          <minus-outlined class="mb-3"/>
        </div>
        <div>アカウント数</div>
      </div>

      <div class="d-flex flex-column gap-3 ms-4 ">
        <div class="chart-container chart-admin">
          <Responsive class="w-full">
            <template #main="{ width }">
              <Chart
                  :size="{ width, height: 420 }"
                  :data="this.company_account"
                  :margin="margin"
                  :direction="direction"
                  :axis="axis">
                <template #layers>
                  <Grid strokeDasharray="2,2"/>
                  <Line :dataKeys="['year', 'companies'] " :lineStyle="{ stroke: '#5B8FF9' }"/>
                  <Line :dataKeys="['year', 'accounts']" :lineStyle="{ stroke: '#5AD8A6' }"/>
                </template>

                <template #widgets class="row">
                  <Tooltip
                      borderColor="#48CAE4"
                      :config="{
                                          year: { label: '日付', color: 'pink' },
                                          companies: { label: '企業数', color: '#5B8FF9' },
                                          accounts: { label:'アカウント数', color: '#5AD8A6' },
                                          inc: { hide: true }
                                        }"
                  />
                </template>
              </Chart>
            </template>
          </Responsive>
        </div>
      </div>
    </div>
    <div class="content mt-3">
      <div class="row">
        <div class="col-md-7 col-xl-4 fs-4 ms-4">
          直近50最終ログイン
        </div>
      </div>
      <div class="d-flex flex-column gap-3">
        <a-table :dataSource="this.account" :columns="columns" :pagination=false>
          <template #bodyCell="{ column, text, record }">
            <template v-if="column.dataIndex === 'agency' && record.agency_id">
              <router-link v-if="this.role === '管理者'"
                           :to="{ name: 'agency.edit', params: {agencyId: record.agency_id }}">
                {{ record?.agency }}
              </router-link>
              <p v-else>{{ record?.agency }}</p>
            </template>
            <template v-if="column.dataIndex === 'company' && record.company_id">
              <router-link :to="{ name: 'company.edit', params: {companyId: record.company_id }}">
                {{ record?.company }}
              </router-link>
            </template>
          </template>
        </a-table>
      </div>
    </div>
  </a-layout-content>
</template>

<style lang="scss">
.content-admin .chart-admin .v-tooltip-line-vertical {
  display: none;
}

.content-admin #agency, #company, #account, #new_company {
  min-height: 120px;
}

.content-admin .ant-layout {
  margin-top: 10px;
}

.content-admin .anticon-minus svg {
  margin-top: -15px !important;
}

.content-admin #agency {
  background-color: #5984EE;
}

.content-admin #company {
  background-color: #53BACD;
}

.content-admin #account {
  background-color: #66CF94;
}

.content-admin #new_company {
  background-color: #E66793;
}

.content-admin .box {
  margin-right: 160px;
}

.content-admin .title {
  font-size: 17px;
  color: #FFFFFF;
}

.content-admin .quantity {
  margin-left: 30px;
  font-size: 30px;
  color: #FFFFFF;
}
</style>

<script>
import {HomeModel} from "@/model/home";
import LineChart from "../charts/LineChart.vue";
import {DownOutlined, MenuFoldOutlined, MenuUnfoldOutlined, MinusOutlined,} from "@ant-design/icons-vue";
import {Chart, Grid, Line, Tooltip, Responsive} from 'vue3-charts'
import Info from './header/Info.vue'
import {ref} from "vue";

export default {
  components: {
    MenuFoldOutlined, MenuUnfoldOutlined,
    DownOutlined,
    MinusOutlined,
    LineChart,
    Responsive, Chart, Grid, Line, Tooltip,
    Info
  },
  setup() {
    const direction = ref('horizontal')
    const margin = ref({
      left: 0,
      top: 20,
      right: 20,
      bottom: 0
    })

    const axis = ref({
      primary: {
        type: 'band',
        format: (val) => {
          return val
        }
      },
      secondary: {
        domain: ['dataMin', 'dataMax'],
        type: 'linear',
        ticks: 8
      }
    })

    return {
      direction, margin, axis,
      company_account: ref([]),
    }
  },
  data() {
    return {
      isLoading: true,
      data: [],
      role: null,
      roles: [
        "管理者",
        "代理店管理者",
        "代理店担当者"
      ],
      account: [],
      columns: [
        {
          title: '氏名',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'アカウント種別',
          dataIndex: 'role',
          key: 'role',
        },
        {
          title: '代理店',
          dataIndex: 'agency',
          key: 'agency',
        },
        {
          title: '企業',
          dataIndex: 'company',
          key: 'company',
        },
        {
          title: '最終ログイン日時',
          dataIndex: 'last_login_at',
          key: 'last_login_at',
        },
      ],
    }
  },

  methods: {
    async getList() {
      this.data = await HomeModel.list();
      this.account = this.data.account_login;
      this.account.map(function (account) {
        account.company = account.company?.name;
        account.agency = account.agency?.name;
      });
      this.company_account = this.data.company_account;
    }
  },
  async mounted() {
    this.getList();
    this.role = this.$store.state.role.role
  },
}

</script>
