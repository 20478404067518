<template>
  <div class="screen-title">アカウント詳細</div>
  <div class="content">
    <div class="row">
      <div class="col-md-6">
        <div class="border-left-title mb-3">基本情報</div>
        <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">

          <a-form-item label="氏名" :rules="[{ required: true }]">
            <a-input v-model:value="user.name" :disabled="isLoading"/>
            <span class="text-danger" v-if="isInvalid('name')">
                                {{ invalidMessages('name')[0] }}
                            </span>
          </a-form-item>

          <a-form-item label="氏名（カナ）" :rules="[{ required: true }]">
            <a-input v-model:value="user.name_kana" :disabled="isLoading"/>
            <span class="text-danger" v-if="isInvalid('name_kana')">
                                {{ invalidMessages('name_kana')[0] }}
                            </span>
          </a-form-item>

          <a-form-item label="メールアドレス" :rules="[{ required: true }]">
            <a-input v-model:value="user.email" :disabled="isLoading"/>
            <span class="text-danger" v-if="isInvalid('email')">
                                {{ invalidMessages('email')[0] }}
                            </span>
          </a-form-item>

          <a-form-item label="連絡先電話番号" :rules="[{ required: true }]">
            <a-input v-model:value="user.phone" :disabled="isLoading"/>
            <span class="text-danger" v-if="isInvalid('phone')">
                                {{ invalidMessages('phone')[0] }}
                            </span>
          </a-form-item>

          <a-form-item label="パスワード" :rules="[{ required: true }]" hidden>
            <a-input-password v-model:value="user.password" :disabled="isLoading"/>
            <span class="text-danger" v-if="isInvalid('password')">
                                {{ invalidMessages('password')[0] }}
                            </span>
          </a-form-item>

          <a-form-item label="役職区分" :rules="[{ required: true }]">
            <a-select
                style="display: block;"
                :disabled="isLoading"
                v-model:value="user.energy_role"
                :options="energy_role"/>
          </a-form-item>

          <a-form-item label="部署名" :rules="[{ required: true }]">
            <a-input v-model:value="user.department" :disabled="isLoading"/>
            <span class="text-danger" v-if="isInvalid('department')">
                                {{ invalidMessages('department')[0] }}
                            </span>
          </a-form-item>

          <a-form-item label="役職名" :rules="[{ required: true }]">
            <a-input v-model:value="user.job_title" :disabled="isLoading"/>
            <span class="text-danger" v-if="isInvalid('job_title')">
                                {{ invalidMessages('job_title')[0] }}
                            </span>
          </a-form-item>

          <a-col :span="24">
            <a-form-item label="ステータス　:">
              <a-switch v-model:checked="status" @change="user.status = status === true? 2 : 0">
                <template #checkedChildren>
                  <CheckOutlined class="status-icon"/>
                </template>
                <template #unCheckedChildren>
                  <CloseOutlined class="status-icon"/>
                </template>
              </a-switch>&ensp;
              <a-tag v-if="user.status == 2" color="blue">有効</a-tag>
              <a-tag v-if="user.status == 1" color="orange">仮登録</a-tag>
              <a-tag v-if="user.status == 0" color="red">無効</a-tag>
            </a-form-item>
          </a-col>

          <a-form-item label="メモ">
            <a-textarea v-model:value="user.memo"/>
            <span class="text-danger" v-if="isInvalid('memo')">
                                {{ invalidMessages('memo')[0] }}
                            </span>
          </a-form-item>

        </a-form>
      </div>
      <div class="col-md-6">
        <div class="border-left-title mb-3">権限</div>
        <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">

          <a-form-item label="権限" :rules="[{ required: true }]">
            <a-select v-model:value="user.role" :options="roles"
                      :fieldNames="{ label: 'name', value: 'name' }" style="display: block;"
                      @change="handleSelectRole"
                      :disabled="isLoading"/>
            <span class="text-danger" v-if="isInvalid('role')">
                                {{ invalidMessages('role')[0] }}
                            </span>
          </a-form-item>

          <a-form-item label="代理店" :rules="[{ required: true }]"
                       v-if="this.$store.state.role.role === '管理者' && !selectHidden.agency">
            <a-select @change="filterCompanyByAgency" v-model:value="user.agency_id" :options="agencies"
                      :fieldNames="{ label: 'name', value: 'id' }" style="display: block;"
                      :disabled="isLoading"></a-select>
            <span class="text-danger" v-if="isInvalid('agency_id')">
                                {{ invalidMessages('agency_id')[0] }}
                            </span>
          </a-form-item>

          <a-form-item label="企業" v-if="!this.$store.state.role.role.includes('企業') && !selectHidden.company">
            <a-select @change="filterSelectFacilityByCompany" v-model:value="user.company_id" :options="companies"
                      :fieldNames="{ label: 'name', value: 'id' }" style="display: block;"
                      :disabled="isLoading"></a-select>
            <span class="text-danger" v-if="isInvalid('company_id')">
                                {{ invalidMessages('company_id')[0] }}
                            </span>
          </a-form-item>

          <a-form-item label="施設" v-if="!selectHidden.facility">
            <a-select v-model:value="user.facility_id" :options="facilities"
                      :fieldNames="{ label: 'name', value: 'id' }" style="display: block;"
                      :disabled="isLoading"></a-select>
            <span class="text-danger" v-if="isInvalid('facility_id')">
                                {{ invalidMessages('facility_id')[0] }}
                            </span>
          </a-form-item>

        </a-form>
      </div>
    </div>
    <a-space class="d-flex justify-content-center">
      <a-button danger ghost :disabled="isLoading" @click="goToList">キャンセル</a-button>
      <a-button type="primary" @click="this.visible = true" :loading="isLoading" danger>削除</a-button>
      <a-button type="primary" :loading="isLoading" @click="doSubmit">更新</a-button>
    </a-space>

    <a-modal
      v-model:visible="visible"
      title="削除確認"
      ok-text="はい"
      cancel-text="いいえ"
      @ok="deleteUser"
      @cancel="this.visible = false"
      :bodyStyle="{ borderRadius: '5px' }"
      :width="400"
      :okButtonProps="{ type: 'danger' }"
    >
          <p>
              <ExclamationCircleOutlined class="larger-icon"/>
              本当に削除しますか
              <p class="mt-3">「はい」をクリックすると、このアカウントがログインできなくなります。</p>
          </p>
    </a-modal>
  </div>
</template>

<script>
import UserModel from '@/model/user';
import {agency as AgencyModel} from '@/model/agency';
import {CompanyModel} from '@/model/company';
import {FacilityModel} from '@/model/facility';
import {message, Modal} from 'ant-design-vue';
import {
    CheckOutlined,
    CloseOutlined, ExclamationCircleOutlined,
} from '@ant-design/icons-vue'
import {Confirm} from "notiflix";
import {agency} from "@/model/agency";

export default {
  components: {
    ExclamationCircleOutlined,
    CheckOutlined,
    CloseOutlined,
  },
  data() {
    return {
      isLoading: false,
      visible: false,
      user: {},
      agencies: [],
      companies: [],
      facilities: [],
      roles: [],
      energy_role: [
        {
          label: "該当しない",
          value: 1
        },
        {
          label: "エネルギー管理統轄者",
          value: 2
        },
        {
          label: "エネルギー管理企画推進者",
          value: 3
        },
        {
          label: "エネルギー管理者",
          value: 4
        }
      ],
      status: false,
      selectHidden: {
        agency: false,
        company: false,
        facility: false
      }
    };
  },
  async mounted() {
    await this.getScreenData();
    await this.getUserData();
  },
  methods: {
    goToList() {
      this.$router.push({name: 'user.list'});
    },
    async getScreenData() {
      this.isLoading = true;

      try {
        let user = await this.$store.dispatch('auth/me')

        if (this.$store.state.role.role === '管理者') {
          this.agencies = [...await agency.getAllAgencyNames()]
          this.companies = [...await CompanyModel.getAllCompanyNames()]
          this.facilities = [...await FacilityModel.getAllFacilityNames()]
        } else {
          this.companies = [...await CompanyModel.getCompanyByAgency(user.agency_id)]
          this.facilities = [...await FacilityModel.getFacilityByParent(user.agency_id, user.company_id)]
        }

      } catch (error) {
        if (this.tryGetErrorResponse(error)) {
          message.error(this.errorMessage());
        }
      }

      this.roles = await UserModel.getAllRoles()

      this.isLoading = false;
    },
    async getUserData() {
      this.isLoading = true;

      try {
        let currentUser = await this.$store.dispatch('auth/me')
        await UserModel.detail(this.$route.params.id)
            .then(async res => {
              let checkRole = await this.$store.dispatch('role/checkRole', {
                user: currentUser,
                data: res
              })
              if (this.$store.state.role.role === '管理者' || checkRole) {
                this.user = res
                this.status = this.user.status === 2
                this.handleSelectRole()
                  this.filterCompanyByAgency(true)
              } else {
                message.error(`アクセス権限がありません。`)
                this.$router.push({name: 'user.list'})
              }
            })
      } catch (error) {
        if (this.tryGetErrorResponse(error)) {
          message.error(this.errorMessage());
        }
      }

      this.isLoading = false;
    },
    async doSubmit() {
      this.isLoading = true;
      UserModel.update(this.$route.params.id, {
        ...this.user
      }).then((res) => {
        if (res?.response?.status === 422 || res?.response?.status === 500) {
          this.tryGetErrorResponse(res)
        }
        else if (res?.error == false){
            message.error({
                content: '企業管理者のアカウント数が上限に達しています',
                duration: 0,
                onClick: () => {
                    message.destroy();
                }
            });

            message.error({
                content: '1つの企業につき2つまでの管理者アカウントしか作成できません',
                duration: 0,
                onClick: () => {
                    message.destroy();
                }
            });
            return;
        }
        else {
          message.success(res.message)
          this.$router.push({name: 'user.list'})
        }
      })
      this.isLoading = false;
    },
    deleteUser() {
        this.isLoading = true
        UserModel.deleteUser(this.$route.params.id)
            .then(res => {
              if (res && res.status === true) {
                message.success('削除されました。')
                this.$router.push({name: 'user.list'})
              } else {
                message.error('に削除されました。')
              }
              this.isLoading = false
            })
    },

    async filterCompanyByAgency($type) {
      if ($type != true){
          this.user.company_id = null
          this.user.facility_id = null
      }
      this.companies = await CompanyModel.getCompanyByAgency(this.user.agency_id ?? 0)
      this.facilities = await FacilityModel.getFacilityByParent(this.user.agency_id ?? 0, this.user.company_id ?? 0)
    },

    async filterSelectFacilityByCompany() {
      this.user.facility_id = null
      this.facilities = await FacilityModel.getFacilityByParent(this.user.agency_id ?? 0, this.user.company_id ?? 0)
    },

    handleSelectRole() {
      if (this.user.role === '管理者') {
        this.selectHidden = {
          agency: true,
          company: true,
          facility: true
        }
        this.user.agency_id = null
        this.user.company_id = null
        this.user.facility_id = null
      } else if (this.user.role.includes('代理店')) {
        this.selectHidden = {
          company: true,
          facility: true
        }
        this.user.company_id = null
        this.user.facility_id = null
      } else if (this.user.role.includes('企業')) {
        this.selectHidden = {
          facility: true
        }
        this.user.facility_id = null
      } else {
        this.selectHidden = {
          agency: false,
          company: false,
          facility: false
        }
      }
    },
  }
}
</script>

<style lang="scss">
.status-icon svg {
  margin-bottom: 5px !important;
}
</style>
