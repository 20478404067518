<template>
  <a-layout-header class="bg-light p-0 border-bottom d-flex justify-content-between">
    <div class="d-flex">
      <div style="float: left; font-size: 20px; padding-left: 30px">{{ this.nameCompany }}</div>
      <div style="float: left; font-size: 20px; padding-left: 42px">{{ this.nameFacility }}</div>
    </div>
    <div class="d-flex">
      <div style="margin-right: 100px; font-size: 20px">
        {{ this.currYear }}年{{ this.currMonth }}月{{ this.currDay }}日<span
          class="ms-3">{{ this.currDD }}</span>
      </div>
      <Info/>
    </div>
  </a-layout-header>
  <a-layout-content class="p-4">
    <div class="content" style="border-bottom: 1px solid rgba(0, 0, 0, 0.15)">
      <div class="d-flex justify-content-between">
        <h5>建屋毎比較</h5>
        <a-form-item class="pt-3">
          <a-date-picker v-model:value="this.dateFrom" @change="getDate" picker="month" class="me-2"/>
          ~
          <a-date-picker disabled v-model:value="this.dateTo" picker="month" class="ms-2"/>
        </a-form-item>
      </div>
      <a-form-item>
        <ul class="nav">
          <li class="nav-item">
            <a class="nav-link" style="color: #212529"
               v-bind:class="{ active: (isActiveCo2 === activeId), 'disabled-link':isLoading }"
               v-on:click="selectItem(0)"
               aria-current="page" href="#">CO2</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" style="color: #212529"
               v-bind:class="{ active: (isActiveMoney === activeId), 'disabled-link':isLoading }"
               v-on:click="selectItem(-1)"
               aria-current="page" href="#">料金</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" style="color: #212529"
               v-bind:class="{ active: (isRecycledEnergy === activeId), 'disabled-link':isLoading }"
               v-on:click="selectItem(-2)"
               aria-current="page" href="#">料金(創エネ)</a>
          </li>
          <li class="nav-item" v-for="item in this.dataEnergyType ">
            <a class="nav-link" style="color: #212529"
               v-bind:class="{ active: (item?.id === activeId), 'disabled-link':isLoading }"
               v-on:click="selectItem(item?.id)"
               aria-current="page" href="#">{{ item.name }}</a>
          </li>
        </ul>
      </a-form-item>
      <a-divider style="background-color: #d9d9d9"/>
      <BarChart v-if="this.data && !isLoading " :lable="this.data"></BarChart>
      <div class="loading" v-else style="min-height: 365px">
        <a-spin/>
      </div>
    </div>
  </a-layout-content>
</template>

<script>
import {defineComponent, ref} from 'vue'
import {Chart, Grid, Line} from 'vue3-charts'
import {DownOutlined, MinusOutlined} from "@ant-design/icons-vue";
import {EnergyContractModel} from "@/model/energyContract";
import dayjs from 'dayjs'

import('dayjs/locale/ja')
import {HomeModel} from "@/model/home";
import BarChart from "../charts/BarChart.vue";
import Info from './header/Info.vue'

export default defineComponent({
  name: 'Bar2Chart',
  components: {MinusOutlined, DownOutlined, Chart, Grid, Line, EnergyContractModel, HomeModel, BarChart, Info},
  data() {
    return {
      data: null,
      isLoading: false,
    }
  },
  setup() {
    return {
      arr: [],
      dataEnergyType: ref([]),
      currMonth: dayjs().format('MM'),
      currYear: dayjs().format('YYYY'),
      currDay: dayjs().format('DD'),
      currDD: dayjs().locale('ja').format('dddd'),
      nameCompany: ref(null),
      nameFacility: ref(null),
      dateFrom: ref(null),
      dateTo: ref(null),
      dateFormat: 'YYYY/MM',
      twoYear: ref(true),
      oneYear: ref(true),
      isActiveCo2: ref(0),
      isActiveMoney: ref(-1),
      isRecycledEnergy: ref(-2),
      activeId: ref(0),
      checkYear: ref(true),
      params: ref({
        date: null,
        activeId: 0,
        facilityId: null
      }),
    }
  },

  methods: {
    async getDate() {
      this.isLoading = true
      const dateFrom = new Date(this.dateFrom?.format('YYYY/MM'));
      const date = new Date(dateFrom.setMonth(dateFrom?.getMonth() + 11));
      this.dateTo = dayjs(date.getFullYear() + '/' + (date.getMonth() + 1));
      this.params.date = this.dateTo.format('YYYY/MM');

      await HomeModel.ListDetailCo2ByBuilding(this.params)
          .then(res => {
            this.data = res
            this.nameCompany = res.nameCompany;
            this.nameFacility = res.nameFacility;
            this.isLoading = false
          })
    },
    energyType() {
      EnergyContractModel.getListEnergyType().then(res => {
        this.dataEnergyType = res;
      });
    },
    selectItem(id) {
      this.activeId = id
      this.params.activeId = id
      this.getDate();
    }
  },
  async mounted() {
    this.energyType();
    this.dateFrom = dayjs(JSON.parse(localStorage.getItem('date-chart-facility')));
    this.params.facilityId = this.$router.currentRoute.value.params.facilityId
    const dateFrom = new Date(this.dateFrom?.format('YYYY/MM'));
    const date = new Date(dateFrom.setMonth(dateFrom?.getMonth() + 11));
    this.dateTo = dayjs(date.getFullYear() + '/' + (date.getMonth() + 1))
    this.params.date = this.dateTo.format('YYYY/MM');
    await this.getDate();
  }
})
</script>
<style scoped>

.ant-layout {
  margin-top: 10px;
}

.active {
  color: #1890ff !important;
}

ul li {
  font-size: 15px;
}

.loading {
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin: 20px 0;
}

.disabled-link {
  pointer-events: none;
}
</style>
