<template>
  <div class="content">
    <div class="d-flex justify-content-between">
      <div class="screen-title">
        <exclamation-circle-outlined style="color: #FAAD14"/>&ensp;
        <span>本当に退会してよろしいでしょうか？</span>
      </div>
    </div>

    退会処理を行うと、登録済みのすべての情報が削除されます。<br>
    退会を実行する場合は、必ず下記アンケートにお答えいただいた上で<br>
    同意項目全てにチェックを入れて「退会する」ボタンを押してください。

    <a-row :gutter="24"
           style="background: #F5F1F1; padding: 10px; max-height: 500px; overflow-y: auto; margin-top: 20px">
      <div class="card-body">
        <div class="form-group">
          <b>退会理由アンケートにご協力ください。</b><br>
          ※退会理由の選択は必須となりますので、必ずご回答いただきますようお願い申し上げます。<br>
          <label class="font-weight-bold" for="free189">1.退会理由についてお聞かせください</label>
          <div class="custom-control custom-checkbox">
            <input @change="checkSubmitForm()" class="custom-control-input" type="checkbox" v-model="formSubmit.free189"
                   :value="formData.free189[0]"
                   id="free189_0">
            <label class="custom-control-label" for="free189_0">
              {{ formData.free189[0] }}
            </label>
          </div>
          <div class="custom-control custom-checkbox">
            <input @change="checkSubmitForm()" class="custom-control-input" type="checkbox" v-model="formSubmit.free189"
                   :value="formData.free189[1]"
                   id="free189_1">
            <label class="custom-control-label" for="free189_1">
              {{ formData.free189[1] }}
            </label>
          </div>
          <div class="custom-control custom-checkbox">
            <input @change="checkSubmitForm()" class="custom-control-input" type="checkbox" v-model="formSubmit.free189"
                   :value="formData.free189[2]"
                   id="free189_2">
            <label class="custom-control-label" for="free189_2">
              {{ formData.free189[2] }}
            </label>
          </div>
          <div class="custom-control custom-checkbox">
            <input @change="checkSubmitForm()" class="custom-control-input" type="checkbox" v-model="formSubmit.free189"
                   :value="formData.free189[3]"
                   id="free189_3">
            <label class="custom-control-label" for="free189_3">
              {{ formData.free189[3] }}
            </label>
          </div>
          <div class="custom-control custom-checkbox">
            <input @change="checkSubmitForm()" class="custom-control-input" type="checkbox" v-model="formSubmit.free189"
                   :value="formData.free189[4]"
                   id="free189_4">
            <label class="custom-control-label" for="free189_4">
              {{ formData.free189[4] }}
            </label>
          </div>
          <div class="custom-control custom-checkbox">
            <input @change="checkSubmitForm()" class="custom-control-input" type="checkbox" v-model="formSubmit.free189"
                   :value="formData.free189[5]" id="free189_5">
            <label class="custom-control-label" for="free189_5">
              {{ formData.free189[5] }}
            </label>
          </div>
        </div>
        <div class="form-group">
          <label class="font-weight-bold"
                 for="free190">2_1.質問1で⑤を選ばれた方で、差し支えなければ導入を検討している他社システム名をお聞かせください</label>
          <input @change="checkSubmitForm()" class="form-control" id="free190" type="text" v-model="formSubmit.free190">
        </div>
        <div class="form-group">
          <label class="font-weight-bold"
                 for="free191">2_2.質問1で⑤を選ばれた方で、他社システムは省エネ笑太くんと比較して、どのような点が優れていたのかお聞かせください。</label>
          <div class="custom-control custom-checkbox">
            <input @change="checkSubmitForm()" class="custom-control-input" type="checkbox" v-model="formSubmit.free191"
                   :value="formData.free191[0]"
                   id="free191_0">
            <label class="custom-control-label" for="free191_0">
              {{ formData.free191[0] }}
            </label>
          </div>
          <div class="custom-control custom-checkbox">
            <input @change="checkSubmitForm()" class="custom-control-input" type="checkbox" v-model="formSubmit.free191"
                   :value="formData.free191[1]" id="free191_1">
            <label class="custom-control-label" for="free191_1">
              {{ formData.free191[1] }}
            </label>
          </div>
          <div class="custom-control custom-checkbox">
            <input @change="checkSubmitForm()" class="custom-control-input" type="checkbox" v-model="formSubmit.free191"
                   :value="formData.free191[2]" id="free191_2">
            <label class="custom-control-label" for="free191_2">
              {{ formData.free191[2] }}
            </label>
          </div>
          <div class="custom-control custom-checkbox">
            <input @change="checkSubmitForm()" class="custom-control-input" type="checkbox" v-model="formSubmit.free191"
                   :value="formData.free191[3]" id="free191_3">
            <label class="custom-control-label" for="free191_3">
              {{ formData.free191[3] }}
            </label>
          </div>
        </div>
        <div class="form-group">
          <label class="font-weight-bold"
                 for="free192">3.質問1で⑥を選ばれた方で、差し支えなければ具体的な理由をお聞かせください。</label>
          <input @change="checkSubmitForm()" class="form-control" id="free192" type="text" v-model="formSubmit.free192">
        </div>
        <div class="form-group">
          <label class="font-weight-bold" for="free193">4.省エネ笑太くんの利用満足度についてお聞かせください</label>
          <div class="custom-control custom-checkbox">
            <input @change="checkSubmitForm()" class="custom-control-input" type="radio" v-model="formSubmit.free193"
                   :value="formData.free193[0]" id="free193_0">
            <label class="custom-control-label" for="free193_0">
              {{ formData.free193[0] }}
            </label>
          </div>
          <div class="custom-control custom-checkbox">
            <input @change="checkSubmitForm()" class="custom-control-input" type="radio" v-model="formSubmit.free193"
                   :value="formData.free193[1]" id="free193_1">
            <label class="custom-control-label" for="free193_1">
              {{ formData.free193[1] }}
            </label>
          </div>
          <div class="custom-control custom-checkbox">
            <input @change="checkSubmitForm()" class="custom-control-input" type="radio" v-model="formSubmit.free193"
                   :value="formData.free193[2]" id="free193_2">
            <label class="custom-control-label" for="free193_2">
              {{ formData.free193[2] }}
            </label>
          </div>
          <div class="custom-control custom-checkbox">
            <input @change="checkSubmitForm()" class="custom-control-input" type="radio" v-model="formSubmit.free193"
                   :value="formData.free193[3]" id="free193_3">
            <label class="custom-control-label" for="free193_3">
              {{ formData.free193[3] }}
            </label>
          </div>
          <div class="custom-control custom-checkbox">
            <input @change="checkSubmitForm()" class="custom-control-input" type="radio" v-model="formSubmit.free193"
                   :value="formData.free193[4]" id="free193_4">
            <label class="custom-control-label" for="free193_4">
              {{ formData.free193[4] }}
            </label>
          </div>
        </div>
        <div class="form-group">
          <label class="font-weight-bold" for="free194">5.省エネ笑太くんの機能改善についてお聞かせください</label>
          <div class="custom-control custom-checkbox">
            <input @change="checkSubmitForm()" class="custom-control-input" type="checkbox" v-model="formSubmit.free194"
                   :value="formData.free194[0]"
                   id="free194_0">
            <label class="custom-control-label" for="free194_0">
              {{ formData.free194[0] }}
            </label>
          </div>
          <div class="custom-control custom-checkbox">
            <input @change="checkSubmitForm()" class="custom-control-input" type="checkbox" v-model="formSubmit.free194"
                   :value="formData.free194[1]" id="free194_1">
            <label class="custom-control-label" for="free194_1">
              {{ formData.free194[1] }}
            </label>
          </div>
          <div class="custom-control custom-checkbox">
            <input @change="checkSubmitForm()" class="custom-control-input" type="checkbox" v-model="formSubmit.free194"
                   :value="formData.free194[2]" id="free194_2">
            <label class="custom-control-label" for="free194_2">
              {{ formData.free194[2] }}
            </label>
          </div>
          <div class="custom-control custom-checkbox">
            <input @change="checkSubmitForm()" class="custom-control-input" type="checkbox" v-model="formSubmit.free194"
                   :value="formData.free194[3]" id="free194_3">
            <label class="custom-control-label" for="free194_3">
              {{ formData.free194[3] }}
            </label>
          </div>
          <div class="custom-control custom-checkbox">
            <input @change="checkSubmitForm()" class="custom-control-input" type="checkbox" v-model="formSubmit.free194"
                   :value="formData.free194[4]" id="free194_4">
            <label class="custom-control-label" for="free194_4">
              {{ formData.free194[4] }}
            </label>
          </div>
        </div>

        <div class="form-group">
          <label class="font-weight-bold" for="free195">6.質問5で⑤その他を選ばれた方で、差し支えなければ具体的な改善点についてお聞かせください。</label>
          <input @change="checkSubmitForm()" class="form-control" id="free195" type="text" v-model="formSubmit.free195">
        </div>
      </div>
    </a-row>

    <a-row :gutter="24" class="mt-3">
      <a-col :span="24">
        <a-checkbox @change="checkSubmitForm()" v-model:checked="conditional0">アカウント内の全ての情報は削除されます</a-checkbox>
      </a-col>
      <a-col :span="24">
        <a-checkbox @change="checkSubmitForm()" v-model:checked="conditional1">退会規約に同意する</a-checkbox>
      </a-col>
      <a-col :span="24">
        <a-checkbox @change="checkSubmitForm()" v-model:checked="conditional2">退会後、ログインできなくなう</a-checkbox>
      </a-col>
      <a-col :span="24">
        <a-checkbox @change="checkSubmitForm()" v-model:checked="conditional3">退会後、データ復活できないことを了承する</a-checkbox>
      </a-col>
    </a-row>

    <a-row :gutter="24" class="mt-3 text-end d-block">
      <a-button class="mx-2" type="primary" ghost html-type="button"
                @click="$router.push({name: 'home'})">キャンセル
      </a-button>
      <a-button type="danger" html-type="button" @click="confirm"
                :disabled="submitForm">退会する
      </a-button>
      <a-modal v-model:open="openModal">
        この操作は戻ることができません
      </a-modal>
    </a-row>

  </div>
</template>
<script setup lang="ts">
import {
  ExclamationCircleOutlined
} from '@ant-design/icons-vue'
import {ref, createVNode} from "vue";
import {Modal} from 'ant-design-vue';
import UserModel from '@/model/user';
import store from "@/stores/_loader";
import {useRouter} from "vue-router";

const conditional0 = ref(false)
const conditional1 = ref(false)
const conditional2 = ref(false)
const conditional3 = ref(false)
const openModal = ref<boolean>(false)
const router = useRouter();
const formData = ref({
  free189: [
    '①二重に会員登録をしてしまった',
    '②システムの機能不足',
    '③システムの使い方が難しい',
    '④必要な情報を得られなかった',
    '⑤他社システムを利用するため',
    '⑥その他'
  ],
  free191: [
    '①他社システムの方が機能が魅力的',
    '②他社システムの方がデザインや視認性に優れている',
    '③他社システムの方が使いやすさが優れている',
    '④他社システムの方が将来のアップデートや拡張性に期待できる'
  ],
  free193: [
    '①非常に満足',
    '②満足',
    '③普通',
    '④不満',
    '⑤非常に不満'
  ],
  free194: [
    '①もっと詳細なデータ表示が欲しい',
    '②グラフやチャートの種類を増やしてほしい',
    '③アラート設定の柔軟性を高めてほしい',
    '④ユーザー間のデータ共有機能がほしい',
    '⑤その他'
  ]
});
const formSubmit = ref({
  free189: [],
  free190: '',
  free191: [],
  free192: '',
  free193: [],
  free194: [],
  free195: ''
});
const submitForm = ref(true)
const handleOk = () => {
  UserModel.unregister(formSubmit.value)
      .then(res => {
        store.commit('auth/removeUser')
        store.dispatch('role/removeRole')
        router.push({name: 'delete-completed'});
      })
};
const confirm = () => {
  Modal.confirm({
    title: '本当に退会してよろしいでしょうか？',
    icon: createVNode(ExclamationCircleOutlined),
    content: 'この操作は戻ることができません',
    okText: '退会する',
    cancelText: 'キャンセル',
    onOk: handleOk,
    okButtonProps: {danger: true},
    cancelButtonProps: {ghost: true, type: 'primary'},
    centered: true,
    visible: openModal.value
  });
};

const checkSubmitForm = () => {
  let enableBtn
  if (formSubmit.value.free189.includes('⑤他社システムを利用するため') && (formSubmit.value.free190 === '' || formSubmit.value.free191.length === 0)) {
    enableBtn = true
  } else if(formSubmit.value.free189.includes('⑥その他') && formSubmit.value.free192 === '') {
    enableBtn = true
  } else if (formSubmit.value.free194.includes('⑤その他') && formSubmit.value.free195 === '') {
    enableBtn = true
  } else if (formSubmit.value.free189.length === 0
      || formSubmit.value.free193.length === 0
      || formSubmit.value.free194.length === 0
      || !conditional0.value
      || !conditional1.value
      || !conditional2.value
      || !conditional3.value) {
    enableBtn = true
  } else {
    enableBtn = false
  }
  submitForm.value = enableBtn
}
</script>
