<template>
  <div class="d-flex justify-content-between">
    <div class="screen-title">エネルギー契約登録</div>
  </div>
  <div class="content">
    <a-form :label-col="labelCol">
      <a-row :gutter="24">
        <a-col class="px-3" :span="12">
          <a-col :span="24">
            <a-form-item
                label="エネルギー種類"
                :required="true">
              <a-select
                  placeholder="エネルギー種類"
                  v-model:value="energyContract.energy_type_id"
                  :disabled="isLoading"
                  :fieldNames="{label: 'name', value: 'id'}"
                  :options="energyTypes"
                  @change="handleSelectType"
                  :class="[isInvalid('energy_type_id') ? 'border-danger-select' : '']"/>
              <span class="text-danger" v-if="isInvalid('energy_type_id')">
                                {{ invalidMessages('energy_type_id')[0] }}
                            </span>
            </a-form-item>
          </a-col>

          <a-col :span="24" v-if="this.$store.state.role.role === '管理者'">
            <a-form-item
                label="代理店"
                :required="true">
              <a-select
                  placeholder="代理店"
                  v-model:value="energyContract.agency_id"
                  :disabled="isLoading"
                  :fieldNames="{label: 'name', value: 'id'}"
                  :options="agencies"
                  :class="[isInvalid('agency_id') ? 'border-danger-select' : '']"
                  @change="filterCompanyByAgency"/>
              <span class="text-danger" v-if="isInvalid('agency_id')">
                                {{ invalidMessages('agency_id')[0] }}
                            </span>
            </a-form-item>
          </a-col>

          <a-col :span="24"
                 v-if="!this.$store.state.role.role.includes('企業') && !this.$store.state.role.role.includes('施設')">
            <a-form-item
                label="企業"
                :required="true">
              <a-select
                  placeholder="企業"
                  v-model:value="energyContract.company_id"
                  :disabled="isLoading"
                  :fieldNames="{label: 'name', value: 'id'}"
                  :options="companies"
                  @change="handleSelectType"
                  :class="[isInvalid('company_id') ? 'border-danger-select' : '']"/>
              <span class="text-danger" v-if="isInvalid('company_id')">
                                {{ invalidMessages('company_id')[0] }}
                            </span>
            </a-form-item>
          </a-col>

          <a-col :span="24" v-if="!this.$store.state.role.role.includes('施設')">
            <a-form-item
                label="施設">
              <a-select
                  placeholder="施設"
                  v-model:value="energyContract.facility_id"
                  :disabled="isLoading"
                  :fieldNames="{label: 'name', value: 'id'}"
                  :options="facilities"
                  @change="filterSelectBuilding"
                  :class="[isInvalid('facility_id') ? 'border-danger' : '']"/>
              <span class="text-danger" v-if="isInvalid('facility_id')">
                                {{ invalidMessages('facility_id')[0] }}
                            </span>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item
                label="建屋">
              <a-select
                  placeholder="建屋"
                  v-model:value="energyContract.building_id"
                  :disabled="isLoading"
                  :fieldNames="{label: 'name', value: 'id'}"
                  :options="buildings"
                  :class="[isInvalid('building_id') ? 'border-danger' : '']"/>
              <span class="text-danger" v-if="isInvalid('building_id')">
                                {{ invalidMessages('building_id')[0] }}
                            </span>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item label="メモ">
              <a-textarea v-model:value="energyContract.memo" :disabled="isLoading"
                          :class="[isInvalid('memo') ? 'border-danger' : '']"/>
              <span class="text-danger" v-if="isInvalid('memo')">
                                {{ invalidMessages('memo')[0] }}
                            </span>
            </a-form-item>
          </a-col>
        </a-col>

        <a-col class="px-3" :span="12">

          <a-col :span="24">
            <a-form-item
                label="契約期間">
              <a-date-picker
                  :value-format="dateFormat"
                  class="me-4 w-40"
                  v-model:value="energyContract.term_start"
                  :class="[isInvalid('term_start') ? 'border-danger' : '']"/>
              ~
              <a-date-picker
                  :value-format="dateFormat"
                  class="ms-4 w-40"
                  v-model:value="energyContract.term_end"
                  :class="[isInvalid('term_end') ? 'border-danger' : '']"/>
              <span class="text-danger" v-if="isInvalid('term_end')">
                                {{ invalidMessages('term_end')[0] }}
                            </span>
            </a-form-item>
          </a-col>

          <a-col :span="24" v-if="energyTypeSelected === '電気'">
            <a-form-item
                label="契約先"
                :rules="[{ required:true}]">
              <a-select
                  placeholder="契約先"
                  show-search
                  v-model:value="energyContract.electric_company_id"
                  :disabled="isLoading"
                  :fieldNames="{label: 'name', value: 'id'}"
                  :options="electricCompanies"
                  :filter-option="filterOption"
                  @change="handleSelectType"
                  :class="[isInvalid('electric_company_id') ? 'border-danger' : '']"/>
              <span class="text-danger" v-if="isInvalid('electric_company_id')">
                                {{ invalidMessages('electric_company_id')[0] }}
                            </span>
            </a-form-item>

            <a-form-item
                v-if="energyTypeSelected === '電気' && companyTypeSelected && companyTypeSelected !== 0 && companyTypeSelected !== undefined"
                label="メニュー">
              <a-select
                  placeholder="メニュー"
                  show-search
                  v-model:value="energyContract.electric_menu_id"
                  :disabled="isLoading"
                  :fieldNames="{label: 'name', value: 'id'}"
                  :options="electricMenus"
                  :filter-option="filterOption"
                  @change="handleCo2Coefficient"
                  :class="[isInvalid('electric_menu_id') ? 'border-danger' : '']"/>
              <span class="text-danger" v-if="isInvalid('electric_menu_id')">
                                {{ invalidMessages('electric_menu_id')[0] }}
                            </span>
            </a-form-item>
          </a-col>

          <a-col :span="24" v-else>
            <a-form-item
                label="契約先"
                :required="true"
                >
              <a-input
                  v-model:value="energyContract.contract_company_name"
                  placeholder="契約先"
                  :disabled="isLoading"
                  :class="[isInvalid('electric_company_id') ? 'border-danger' : '']"/>
              <span class="text-danger" v-if="isInvalid('electric_company_id')">
                                {{ invalidMessages('electric_company_id')[0] }}
                            </span>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item
                label="CO2変換係数"
                :required="true">
              <a-input
                  v-model:value="energyContract.co2_convert_coefficient"
                  placeholder="CO2変換係数"
                  :disabled="isLoading"
                  :class="[isInvalid('co2_convert_coefficient') ? 'border-danger' : '']"/>
              <span class="text-danger" v-if="isInvalid('co2_convert_coefficient')">
                {{ invalidMessages('co2_convert_coefficient')[0] }}
              </span>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item
                label="ファイル添付">
              <input type="file" ref="upload_file" @change="selectFile" hidden="hidden"
                     multiple="multiple"/>
              <a-button :disabled="isLoading" @click="$refs.upload_file.click()">
                <UploadOutlined/>
                Upload
              </a-button>
            </a-form-item>
            <div class="offset-2 ps-2em">
              <li v-for="(item, index) in listFile">
                <PaperClipOutlined class="attack-file"/>
                {{ item }}
              </li>
            </div>
          </a-col>
        </a-col>
      </a-row>

      <div class="text-center">
        <a-button class="mx-2" :loading="isLoading" @click="$router.push({ name: 'energy_contract.list' })"
                  danger>キャンセル
        </a-button>
        <a-button class="mx-2" type="primary" :loading="isLoading" @click="create">登録</a-button>
      </div>

    </a-form>
  </div>
</template>

<script>
import {
  UploadOutlined,
  PaperClipOutlined
} from '@ant-design/icons-vue'
import {EnergyContractModel} from "@/model/energyContract";
import {CompanyModel} from "@/model/company";
import {FacilityModel} from "@/model/facility";
import {BuildingModel} from "@/model/building";
import {agency} from "@/model/agency";
import {message} from 'ant-design-vue';
import dayjs from "dayjs";
import {MasterModel} from "@/model/master";
import _ from "lodash";

const currentDay = dayjs()

export default {
  components: {
    UploadOutlined,
    PaperClipOutlined
  },
  data() {
    return {
      isLoading: true,
      labelCol: {span: 5},
      energyContract: {
        energy_type_id: null,
        agency_id: null,
        company_id: null,
        facility_id: null,
        building_id: null,
        memo: null,
        contract_company_name: null,
        co2_convert_coefficient: null,
        term_start: currentDay,
        term_end: currentDay,
        electric_company_id: null,
        electric_menu_id: null
      },
      files: null,
      agencies: [],
      energyTypes: [],
      companies: [],
      facilities: [],
      buildings: [],
      listFile: [],
      electricCompanies: [],
      electricMenus: [],
      dateFormat: 'YYYY/MM/DD',
      energyTypeSelected: '',
      companyTypeSelected: null
    }
  },
  async mounted() {
    this.energyTypes = [...await EnergyContractModel.getListEnergyType()]
    this.electricCompanies = [...await MasterModel.getElectricCompanies()]

    let user = await this.$store.dispatch('auth/me')
    if (this.$store.state.role.role === '管理者') {
      this.agencies = await agency.getAllAgencyNames()
      this.companies = await CompanyModel.getAllCompanyNames()
      this.facilities = await FacilityModel.getAllFacilityNames()
    } else {
      this.energyContract.agency_id = user.agency_id
      this.energyContract.company_id = user.company_id
      this.companyTypeSelected = user.company.business_type
      this.energyContract.facility_id = user.facility_id
      this.companies = [...await CompanyModel.getCompanyByAgency(user.agency_id)]
      this.facilities = [...await FacilityModel.getFacilityByParent(user.agency_id, user.company_id)]
    }

    this.buildings = [...await BuildingModel.getListBuilding({
      agency_id: this.energyContract.agency_id ?? 0,
      company_id: this.energyContract.company_id ?? 0,
      facility_id: this.energyContract.facility_id ?? 0
    })]
    this.isLoading = false
  },
  methods: {
    create() {
      this.clearError()
      this.isLoading = true
      this.energyContract.term_start = this.energyContract.term_start !== null ? dayjs(this.energyContract.term_start).format('YYYY-MM-DD') : null
      this.energyContract.term_end = this.energyContract.term_end !== null ? dayjs(this.energyContract.term_end).format('YYYY-MM-DD') : null
      EnergyContractModel.create(this.energyContract, this.files)
          .then(res => {
            if (res?.response?.status === 422) {
              this.tryGetErrorResponse(res)
            } else {
              if (res.success) {
                message.success(res.message)
              } else {
                  if (res.response.status === 413){
                      message.error("1ファイルあたりの最大アップロードサイズは50MBまでです。")
                      this.isLoading = false
                  }else {
                      message.error(res.message)
                  }
              }
              this.$router.push({name: 'energy_contract.list'})
            }
            this.isLoading = false
          }).catch(error => {
              if (error.response && error.response.status === 413) {
                  message.error("1ファイルあたりの最大アップロードサイズは50MBまでです。")
              }
          })
    },

    selectFile(e) {
      this.files = e.target.files
      this.listFile = []

      for (let i = 0; i < this.files.length; i++) {
        let file = this.files[i];
        this.listFile.push(file?.name)
      }
    },

    async handleSelectType() {
      this.energyTypes.map(val => {
        if (val.id === this.energyContract.energy_type_id)
          this.energyTypeSelected = val.name
      })

      this.companies.map(val => {
        if (val.id === this.energyContract.company_id)
          this.companyTypeSelected = val.business_type
      })

      if (this.energyTypeSelected === '電気' && this.companyTypeSelected && this.companyTypeSelected !== 0 && this.companyTypeSelected !== undefined) {
        let parent = this.energyContract.electric_company_id !== null ? this.energyContract.electric_company_id : 0
        this.electricMenus = [...await MasterModel.getElectricMenus(parent)]
      } else {
        this.electricMenus = [];
      }
      this.energyContract.electric_menu_id = null
      this.energyContract.contract_company_name = null
      this.handleCo2Coefficient()
    },

    handleCo2Coefficient() {
      let selectedElectricCompany = _.findIndex(this.electricCompanies, ['id', this.energyContract.electric_company_id])
      let base_emission_factor = selectedElectricCompany !== -1 ? parseFloat(this.electricCompanies[selectedElectricCompany].base_emission_factor) : 0
      let selectedMenu = _.findIndex(this.electricMenus, ['id', this.energyContract.electric_menu_id])
      let adjusted_emission_factor = selectedMenu !== -1 ? parseFloat(this.electricMenus[selectedMenu].adjusted_emission_factor) : 0

      if (this.energyTypeSelected !== '電気') {
        let energyTypeObj = _.filter(this.energyTypes, {id: this.energyContract.energy_type_id})
        this.energyContract.co2_convert_coefficient = energyTypeObj[0].value ?? 0
      } else if (this.companyTypeSelected === 0) {
        this.energyContract.co2_convert_coefficient = base_emission_factor
      } else {
        this.energyContract.co2_convert_coefficient = adjusted_emission_factor
      }
    },

    async filterCompanyByAgency() {
      this.energyContract.company_id = null
      this.energyContract.facility_id = null
      this.energyContract.building_id = null
      this.companyTypeSelected = null
      this.companies = await CompanyModel.getCompanyByAgency(this.energyContract.agency_id ?? 0)
      this.facilities = await FacilityModel.getFacilityByParent(this.energyContract.agency_id ?? 0, this.energyContract.company_id ?? 0)
      this.buildings = await BuildingModel.getListBuilding({
        agency_id: this.energyContract.agency_id ?? 0,
        company_id: this.energyContract.company_id ?? 0,
        facility_id: this.energyContract.facility_id ?? 0
      })
    },

    async filterSelectFacilityByCompany() {
      this.energyContract.facility_id = null
      this.energyContract.building_id = null
      this.facilities = await FacilityModel.getFacilityByParent(this.energyContract.agency_id ?? 0, this.energyContract.company_id ?? 0)
      this.buildings = await BuildingModel.getListBuilding({
        agency_id: this.energyContract.agency_id ?? 0,
        company_id: this.energyContract.company_id ?? 0,
        facility_id: this.energyContract.facility_id ?? 0
      })
    },

    async filterSelectBuilding() {
      this.energyContract.building_id = null
      this.buildings = await BuildingModel.getListBuilding({
        agency_id: this.energyContract.agency_id ?? 0,
        company_id: this.energyContract.company_id ?? 0,
        facility_id: this.energyContract.facility_id ?? 0
      })
    },

    filterOption(input, option) {
      return option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }
  }
}
</script>

<style scoped>
.attack-file {
  color: #00000073;
  margin-bottom: 5px;
}
</style>
