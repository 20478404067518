<template>
  <div class="d-flex justify-content-between">
    <div class="screen-title">使用状況登録</div>
  </div>
  <div class="content">
    <a-form :label-col="labelCol">
      <a-row :gutter="24">
        <a-col class="px-3" :span="12">

          <a-col :span="24">
            <a-form-item
                label="エネルギー種類"
                :rules="[{ required:true}]">
              <a-select
                  placeholder="エネルギー種類"
                  v-model:value="usage.energy_type_id"
                  :loading="initPageData"
                  :fieldNames="{label: 'name', value: 'id'}"
                  :options="energyTypes"
                  show-search
                  @change="handleChangeContractParams"
                  :filter-option="filterOption"/>
            </a-form-item>
          </a-col>

          <a-col :span="24" v-if="energyName === '創エネ'">
            <a-form-item
                label="創エネ種類"
                :rules="[{ required:true}]">
              <a-select
                  placeholder="創エネ種類"
                  v-model:value="usage.detail_type"
                  :loading="initPageData"
                  :fieldNames="{label: 'name', value: 'id'}"
                  :options="detailTypeList"
                  show-search/>
              <span class="text-danger" v-if="isInvalid('detail_type')">
                                {{ invalidMessages('detail_type')[0] }}
                            </span>
            </a-form-item>
          </a-col>

          <a-col :span="24" v-if="this.$store.state.role.role === '管理者'">
            <a-form-item
                :rules="[{ required:true}]"
                label="代理店">
              <a-select
                  v-model:value="usage.agency_id"
                  show-search
                  :loading="initPageData"
                  :fieldNames="{ label: 'name', value: 'id' }"
                  @change="filterCompanyByAgency"
                  :options="agencies"/>
              <span class="text-danger" v-if="isInvalid('agency_id')">
                                {{ invalidMessages('agency_id')[0] }}
                            </span>
            </a-form-item>
          </a-col>

          <a-col :span="24"
                 v-if="!this.$store.state.role.role.includes('企業') && !this.$store.state.role.role.includes('施設')">
            <a-form-item
                label="企業"
                :rules="[{ required:true}]">
              <a-select
                  placeholder="企業"
                  :options="companies"
                  :loading="initPageData"
                  :fieldNames="{label: 'name', value: 'id'}"
                  v-model:value="usage.company_id"
                  show-search
                  @change="filterSelectFacilityByCompany"
                  :filter-option="filterOption"/>
            </a-form-item>
          </a-col>

          <a-col :span="24" v-if="!this.$store.state.role.role.includes('施設')">
            <a-form-item
                label="施設">
              <a-select
                  placeholder="施設"
                  :options="facilities"
                  :loading="initPageData"
                  :fieldNames="{label: 'name', value: 'id'}"
                  v-model:value="usage.facility_id"
                  show-search
                  @change="filterSelectBuilding"
                  :filter-option="filterOption"/>
              <span class="text-danger" v-if="isInvalid('facility_id')">
                                {{ invalidMessages('facility_id')[0] }}
                            </span>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item
                label="建屋">
              <a-select
                  placeholder="建屋"
                  :options="buildings"
                  :loading="initPageData"
                  :rules="[{ required:true}]"
                  :required="true"
                  :fieldNames="{label: 'name', value: 'id'}"
                  v-model:value="usage.building_id"
                  show-search
                  @change="handleChangeContractParams"
                  :filter-option="filterOption"/>
              <span class="text-danger" v-if="isInvalid('building_id')">
                                {{ invalidMessages('building_id')[0] }}
                            </span>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item
                label="CO2変換係数">
              <a-input :readonly="true" v-model:value="usage.co2_convert_coefficient"/>
            </a-form-item>
            <a-button type="primary" class="ms-2" @click="getContact" :loading="initPageData"
                      v-if="energyName !== '創エネ'">
              契約情報から取得
            </a-button>
          </a-col>

        </a-col>

        <a-col class="px-3" :span="12">

          <a-col :span="24">
            <a-form-item
                :rules="[{ required:true}]">
              <template #label v-if="energyName === '創エネ'">期間</template>
              <template #label v-else>使用期間</template>
              <a-date-picker
                  :value-format="dateFormat"
                  class="me-4 w-40"
                  :disabled="checkHaveContract"
                  v-model:value="usage.usage_from_date"/>
              ~
              <a-date-picker
                  :value-format="dateFormat"
                  class="ms-4 w-40"
                  :disabled="checkHaveContract"
                  v-model:value="usage.usage_to_date"
                  :class="[isInvalid('usage_to_date') ? 'border-danger' : '']"/>
              <br>
              <span class="text-danger" v-if="isInvalid('usage_to_date')">
                {{ invalidMessages('usage_to_date')[0] }}
              </span>
              <span class="text-danger" v-if="isInvalid('usage_from_date')">
                {{ invalidMessages('usage_from_date')[0] }}
              </span>
            </a-form-item>
          </a-col>

          <a-col :span="24" v-if="energyName !== '創エネ'">
            <a-form-item label="請求日">
              <a-date-picker
                  placeholder="請求日"
                  class="w-40"
                  :disabled="checkHaveContract"
                  :value-format="dateFormat"
                  v-model:value="usage.billing_date"
                  :class="[isInvalid('billing_date') ? 'border-danger' : '']"/>
              <span class="text-danger" v-if="isInvalid('billing_date')">
                                {{ invalidMessages('billing_date')[0] }}
                            </span>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item label="支払い日">
              <a-date-picker
                  placeholder="支払い日"
                  class="w-40"
                  :disabled="checkHaveContract"
                  :value-format="dateFormat"
                  v-model:value="usage.invoice_date"
                  :class="[isInvalid('invoice_date') ? 'border-danger' : '']"/>
              <span class="text-danger" v-if="isInvalid('invoice_date')">
                                {{ invalidMessages('invoice_date')[0] }}
                            </span>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item
                :rules="[{ required:true}]">
              <template #label v-if="energyName === '創エネ'">発電量</template>
              <template #label v-else>使用量</template>
              <a-input
                  class="w-40"
                  placeholder="発電量"
                  v-model:value="usage.amount"
                  :disabled="checkHaveContract"
                  @change="handleChangeUsedAmount"
                  :class="[isInvalid('amount') ? 'border-danger' : '']"/>
              {{ energyUnit }}
              <span class="text-danger" v-if="isInvalid('amount')">
                                {{ invalidMessages('amount')[0] }}
                            </span>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item
                :rules="[{ required:true}]">
              <template #label v-if="energyName === '創エネ'">金額</template>
              <template #label v-else>総額</template>
              <a-input
                  class="w-40"
                  placeholder="金額"
                  :disabled="checkHaveContract"
                  v-model:value="money"
                  @change="handleMoney"
                  :class="[isInvalid('money') ? 'border-danger' : '']"/>&ensp;円
              <span class="text-danger" v-if="isInvalid('money')">
                                {{ invalidMessages('money')[0] }}
                            </span>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item
                label="ファイル添付">
              <input type="file" ref="upload_file" @change="selectFile" hidden="hidden"
                     multiple="multiple"/>
              <a-button :disabled="checkHaveContract" class="w-40" @click="$refs.upload_file.click()">
                <UploadOutlined/>
                Upload
              </a-button>
            </a-form-item>
            <div class="offset-2 ps-2em">
              <li v-for="(item, index) in listFile">
                <PaperClipOutlined class="attack-file"/>
                {{ item }}
              </li>
            </div>
          </a-col>

        </a-col>
      </a-row>

      <div class="text-center">
        <a-button
            class="mx-2"
            :disabled="checkHaveContract"
            :loading="initPageData"
            @click="$router.push({ name: 'usage.list' })"
            danger>
          キャンセル
        </a-button>
        <a-button
            class="mx-2"
            type="primary"
            :disabled="checkHaveContract"
            :loading="initPageData"
            @click="create">
          登録
        </a-button>
      </div>

    </a-form>
  </div>
</template>

<script>
import {
  UploadOutlined,
  PaperClipOutlined
} from '@ant-design/icons-vue'
import dayjs from 'dayjs';
import {CompanyModel} from "@/model/company"
import {FacilityModel} from "@/model/facility"
import {BuildingModel} from "@/model/building"
import {EnergyContractModel} from "@/model/energyContract";
import {UsageModel} from "@/model/usage";
import {agency} from "@/model/agency";
import {message} from 'ant-design-vue';
import _ from "lodash";

const currentDay = dayjs()

export default {
  components: {
    UploadOutlined,
    PaperClipOutlined
  },
  data() {
    const filterOption = (input, option) => {
      return option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };
    return {
      filterOption,
      initPageData: true,
      checkHaveContract: true,
      files: null,
      listFile: [],
      labelCol: {span: 5},
      dateFormat: 'YYYY/MM/DD',
      usage: {
        detail_type: null,
        company_id: null,
        facility_id: null,
        building_id: null,
        usage_from_date: currentDay,
        usage_to_date: currentDay,
        billing_date: currentDay,
        invoice_date: currentDay,
        amount: 0,
        money: null,
        converted_co2_amount: 0,
        co2_convert_coefficient: 0,
        energy_contract_id: null,
        agency_id: null,
        energy_type_id: null,
      },
      energyTypes: [],
      companies: [],
      facilities: [],
      buildings: [],
      agencies: [],
      energyUnit: null,
      energyName: null,
      detailTypeList: [
        {
          id: 1,
          name: '太陽光'
        },
        {
          id: 2,
          name: '風力'
        },
        {
          id: 3,
          name: '地熱'
        },
        {
          id: 4,
          name: 'その他'
        }
      ],
      money: null
    }
  },
  async mounted() {
    this.energyTypes = [...await EnergyContractModel.getListEnergyType()]
    let user = await this.$store.dispatch('auth/me')

    if (this.$store.state.role.role === '管理者') {
      this.agencies = [...await agency.getAllAgencyNames()]
      this.companies = [...await CompanyModel.getAllCompanyNames()]
      this.facilities = [...await FacilityModel.getAllFacilityNames()]
    } else {
      this.usage.agency_id = user.agency_id
      this.usage.company_id = user.company_id
      this.usage.facility_id = user.facility_id
      this.companies = [...await CompanyModel.getCompanyByAgency(user.agency_id ?? 0)]
      this.facilities = [...await FacilityModel.getFacilityByParent(user.agency_id ?? 0, user.company_id ?? 0)]
    }

    this.buildings = [...await BuildingModel.getListBuilding({
      agency_id: this.usage.agency_id ?? 0,
      company_id: this.usage.company_id ?? 0,
      facility_id: this.usage.facility_id ?? 0
    })]

    let copyId = !isNaN(parseInt(this.$route.query.copyId)) ? parseInt(this.$route.query.copyId) : null
    if (copyId) {
      this.getDetail(copyId)
    }

    this.initPageData = false
  },
  methods: {
    async getDetail(usageId) {
      let user = await this.$store.dispatch('auth/me')
      UsageModel.getDetail(usageId)
          .then(async resData => {
            let fileList = []
            if (resData?.data?.files) {
              let files = resData?.data?.files
              files.map(val => {
                fileList.push({
                  name: val.name,
                  path: val.path
                })
              })
            }
            this.fileList = fileList

            if (resData && resData.success === false) {
              message.error(resData.message)
              this.$router.push({name: 'usage.list'})
            } else {
              let checkRole = await this.$store.dispatch('role/checkRole', {
                user: user,
                data: resData.data
              })
              if (this.$store.state.role.role === '管理者' || checkRole) {
                this.usage = resData.data
                this.energy_type_id = resData.data.contract?.energy_type?.id
                this.usage.usage_from_date = dayjs.tz(`${this.usage.usage_from_date}`, 'utc')
                this.usage.usage_to_date = dayjs.tz(`${this.usage.usage_to_date}`, 'utc')
                // this.usage.billing_date = dayjs(`${this.usage.billing_date}`)
                this.usage.invoice_date = dayjs.tz(`${this.usage.invoice_date}`, 'utc')
                this.energyTypes.map(val => {
                  if (val.id === this.usage.energy_type_id) {
                    this.energyUnit = val.unit
                    this.energyName = val.name
                  }
                })
                this.checkHaveContract = false
                this.money = Math.abs(this.usage.money)
              } else {
                message.error(`アクセス権限がありません。`)
                this.$router.push({name: 'usage.list'})
              }
            }
            this.isLoading = false
          })
    },

    selectFile(e) {
      this.files = e.target.files
      this.listFile = []

      for (let i = 0; i < this.files.length; i++) {
        let file = this.files[i];
        this.listFile.push(file?.name)
      }
    },

    getContact() {
      this.initPageData = true
      EnergyContractModel.getContractByCondition({
        energy_type: this.usage.energy_type_id,
        company_id: this.usage.company_id,
        facility_id: this.usage.facility_id,
        building_id: this.usage.building_id,
      }).then((res) => {
        if (res) {
          this.usage.co2_convert_coefficient = res.co2_convert_coefficient
          this.usage.energy_contract_id = res.id
          this.usage.agency_id = res.agency_id
          this.checkHaveContract = false
          message.success('契約情報が取得しました。')
        } else {
          message.error('契約情報が存在しません。')
        }
        this.initPageData = false
      })
    },

    handleChangeContractParams() {
      this.energyTypes.map(val => {
        if (val.id === this.usage.energy_type_id) {
          this.energyUnit = val.unit
          this.energyName = val.name
        }
      })

      if (this.energyName !== '創エネ') {
        if (!this.checkHaveContract) {
          this.usage.co2_convert_coefficient = 0
          this.usage.converted_co2_amount = 0
          this.usage.energy_contract_id = null
          this.usage.agency_id = null
          this.checkHaveContract = true
        }
      } else {
        this.checkHaveContract = false
      }
    },

    handleChangeUsedAmount(event) {
      this.usage.converted_co2_amount = this.usage.amount * this.usage.co2_convert_coefficient
    },

    handleMoney(event) {
      if (this.energyName !== '創エネ') {
        this.usage.money = event.target.value
      } else {
        this.usage.money = -event.target.value
      }

      this.money = event.target.value
    },

    async create() {
      this.clearError()
      this.initPageData = true
      this.usage.usage_from_date = this.usage.usage_from_date ? dayjs(this.usage.usage_from_date).format('YYYY-MM-DD') : null;
      this.usage.usage_to_date = this.usage.usage_to_date ?  dayjs(this.usage.usage_to_date).format('YYYY-MM-DD') : null;
      this.usage.billing_date = this.usage.billing_date ? dayjs(this.usage.billing_date).format('YYYY-MM-DD') : null;
      this.usage.invoice_date = this.usage.invoice_date ? dayjs(this.usage.invoice_date).format('YYYY-MM-DD') : null;
      _.unset(this.usage, 'id')
      console.log(this.usage)

      UsageModel.create(this.usage, this.files)
          .then(res => {
            if (res?.response?.status === 422) {
              this.tryGetErrorResponse(res)
            } else {
              if (res.success) {
                message.success(res.message)
              } else {
                  if (res.response.status === 413){
                      message.error("1ファイルあたりの最大アップロードサイズは50MBまでです。")
                      this.isLoading = false
                  }else {
                      message.error(res.message)
                  }
              }
              this.$router.push({name: 'usage.list'})
            }
            this.initPageData = false
          }).catch(error => {
              if (error.response && error.response.status === 413) {
                  message.error("1ファイルあたりの最大アップロードサイズは50MBまでです。")
              }
          })
    },

    async filterCompanyByAgency() {
      this.usage.company_id = null
      this.usage.facility_id = null
      this.usage.building_id = null
      this.companies = [...await CompanyModel.getCompanyByAgency(this.usage.agency_id ?? 0)]
      this.facilities = [...await FacilityModel.getFacilityByParent(this.usage.agency_id ?? 0, this.usage.company_id ?? 0)]
      this.buildings = [...await BuildingModel.getListBuilding({
        agency_id: this.usage.agency_id ?? 0,
        company_id: this.usage.company_id ?? 0,
        facility_id: this.usage.facility_id ?? 0
      })]
      this.handleChangeContractParams()
    },


    async filterSelectFacilityByCompany() {
      this.usage.facility_id = null
      this.usage.building_id = null
      this.facilities = [...await FacilityModel.getFacilityByParent(this.usage.agency_id ?? 0, this.usage.company_id ?? 0)]
      this.buildings = [...await BuildingModel.getListBuilding({
        agency_id: this.usage.agency_id ?? 0,
        company_id: this.usage.company_id ?? 0,
        facility_id: this.usage.facility_id ?? 0
      })]
      this.handleChangeContractParams()
    },

    async filterSelectBuilding() {
      this.usage.building_id = null
      this.buildings = [...await BuildingModel.getListBuilding({
        agency_id: this.usage.agency_id ?? 0,
        company_id: this.usage.company_id ?? 0,
        facility_id: this.usage.facility_id ?? 0
      })]
      this.handleChangeContractParams()
    },
  }
}
</script>

<style scoped lang="scss">
.w-40 {
  width: 40%;
}
</style>
