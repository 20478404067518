import {authApi} from "./authApi";

const path = '/api/home';
export const HomeModel = {

    /**
     * api create facility
     * @returns {Promise<*>}
     */
    list(params) {
        return authApi.post(`${path}/list`);
    },

    getlistByCompany(params) {
        return authApi.post(`${path}/list-company`, params);
    },

    getlistByFacility(params) {
        return authApi.post(`${path}/get-by-facility`, params);
    },

    getListFacility(params) {
        return authApi.post(`${path}/list-facility`, params);
    },

    getListByBuilding(params) {
        return authApi.post(`${path}/get-by-building`, params);
    },

    /**
     * Api create new company
     * @param params
     * @returns {Promise<*>}
     */
    ListDetailCompany(params) {
        return authApi.post(`${path}/list-detail-company`, params);
    },

    ListDetailFacility(params) {
        return authApi.post(`${path}/list-detail-facility`, params);
    },

    ListDetailCo2ByFacility(params) {
        return authApi.post(`${path}/list-detail-co2-by-facility`, params);
    },

    ListDetailCo2ByBuilding(params) {
        return authApi.post(`${path}/list-detail-co2-by-building`, params);
    },

    ListAlarm(params) {
        return authApi.post(`${path}/get-list-alarm`, params);
    },

}
