import {authApi} from "./authApi";

const path = '/api/master';

export const MasterModel = {
    /**
     *
     * @returns {Promise<*>}
     */
    getListMasterTable()
    {
        return authApi.get(`${path}/get-list-master-table`);
    },

    /**
     *
     * @param table
     * @returns {Promise<Result|*>}
     */
    getMasterTable(table)
    {
        return authApi.post(`${path}/get-master-table`, {table})
    },

    /**
     *
     * @param params
     * @returns {Promise<Result|*>}
     */
    updateRecordMasterTable(params)
    {
        return authApi.post(`${path}/update-record-master-table`, params, false)
    },

    /**
     *
     * @param params
     * @returns {Promise<Result|*>}
     */
    createRecordMasterTable(params)
    {
        return authApi.post(`${path}/create-record-master-table`, params)
    },

    /**
     *
     * @param params
     * @returns {Promise<Result|*>}
     */
    deleteRecordMasterTable(params)
    {
        return authApi.post(`${path}/delete-record-master-table`, params)
    },

    /**
     *
     * @returns {Promise<*>}
     */
    getDivisionTreeView()
    {
        return authApi.get(`${path}/division-tree-view`)
    },

    /**
     *
     * @param files
     * @returns {Promise<*>}
     */
    uploadElectricCompanyFile(files)
    {
        return authApi.postForm(`${path}/upload-electric-company-file`, null, files)
    },

    /**
     *
     * @returns {Promise<*>}
     */
    getElectricCompanyList()
    {
        return authApi.get(`${path}/get-electric-company-list`)
    },

    /**
     *
     * @returns {Promise<*>}
     */
    getElectricCompanies()
    {
        return authApi.get(`${path}/get-electric-companies`)
    },

    /**
     *
     * @param parent_id
     * @returns {Promise<*>}
     */
    getElectricMenus(parent_id)
    {
        return authApi.get(`${path}/${parent_id}/get-electric-menus`)
    },

    /**
     *
     * @param list_id
     * @returns {Promise<*>}
     */
    activeElectricList(list_id)
    {
        return authApi.post(`${path}/active-electric-list`, {list_id})
    }
}
