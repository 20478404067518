<template>
  <div class="d-flex justify-content-between">
    <div class="screen-title">図面登録</div>
  </div>
  <div class="content">
    <a-form :label-col="labelCol">
      <a-row :gutter="24">
        <a-col class="px-3" :span="12">
          <a-col :span="24" v-if="this.$store.state.role.role === '管理者'">
            <a-form-item
                :rules="[{ required:true}]"
                label="代理店">
              <a-select
                  v-model:value="map.agency_id"
                  show-search
                  :loading="isLoading"
                  :fieldNames="{ label: 'name', value: 'id' }"
                  @change="filterCompanyByAgency"
                  :class="[isInvalid('agency_id') ? 'border-danger-select' : '']"
                  :options="agencies"/>
              <span class="text-danger" v-if="isInvalid('agency_id')">
                                {{ invalidMessages('agency_id')[0] }}
                            </span>
            </a-form-item>
          </a-col>

          <a-col :span="24"
                 v-if="!this.$store.state.role.role.includes('企業') && !this.$store.state.role.role.includes('施設')">
            <a-form-item
                :rules="[{ required:true}]"
                label="企業">
              <a-select
                  show-search
                  :loading="isLoading"
                  :fieldNames="{ label: 'name', value: 'id' }"
                  :options="companies"
                  v-model:value="map.company_id"
                  @change="filterSelectFacilityByCompany"
                  :class="[isInvalid('company_id') ? 'border-danger-select' : '']"/>
              <span class="text-danger" v-if="isInvalid('company_id')">
                {{ invalidMessages('company_id')[0] }}
              </span>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item
                label="施設名"
                :required="true"
                v-if="!this.$store.state.role.role.includes('施設')">
              <a-select
                  :options="listFacility"
                  :fieldNames="{label: 'name', value: 'id'}"
                  :disabled="isLoading"
                  v-model:value="map.facility_id"
                  @change="filterSelectBuilding"
                  :class="[isInvalid('facility_id') ? 'border-danger-select' : '']"
              />
              <span class="text-danger" v-if="isInvalid('facility_id')">
                {{ invalidMessages('facility_id')[0] }}
              </span>
            </a-form-item>
          </a-col>

          <a-col :span="24">
                <a-form-item
                    label="建屋名">
                    <a-select
                        placeholder="建屋名"
                        v-model:value="map.building_id"
                        :disabled="isLoading"
                        :fieldNames="{label: 'name', value: 'id'}"
                        :options="buildings"
                        :class="[isInvalid('building_id') ? 'border-danger-select' : '']"/>
                    <span class="text-danger" v-if="isInvalid('building_id')">
                      {{ invalidMessages('building_id')[0] }}
                    </span>
                </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item
                label="図面区分"
                :required="true"
            >
              <a-select
                  v-model:value="map.drawing_type_id"
                  :options="listDrawing"
                  :fieldNames="{label: 'name', value: 'id'}"
                  :disabled="isLoading"
                  @change="map.drawing_type = null"
                  :class="[isInvalid('drawing_type_id') ? 'border-danger-select' : '']"
              />
              <span class="text-danger" v-if="isInvalid('drawing_type_id')">
                {{ invalidMessages('drawing_type_id')[0] }}
              </span>
            </a-form-item>
          </a-col>

          <a-col :span="24" v-if="map.drawing_type_id === 0">
            <a-form-item
                label="図面区分"
                :required="true">
              <a-input
                  v-model:value="map.drawing_type"
                  :disabled="isLoading"
                  :class="[isInvalid('drawing_type') ? 'border-danger' : '']"
              />
              <span class="text-danger" v-if="isInvalid('drawing_type')">
                {{ invalidMessages('drawing_type')[0] }}
              </span>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item
                :required="true"
                label="図面番号"
            >
              <a-input
                  v-model:value="map.code"
                  :disabled="isLoading"
                  :class="[isInvalid('code') ? 'border-danger' : '']"
              />
              <span class="text-danger" v-if="isInvalid('code')">
                {{ invalidMessages('code')[0] }}
              </span>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item
                label="保管場所">
              <a-input
                  v-model:value="map.storage_location"
                  :disabled="isLoading"
              />
              <span class="text-danger" v-if="isInvalid('storage_location')">
                                {{ invalidMessages('storage_location')[0] }}
                            </span>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item
                label="作成日">
              <a-date-picker
                  v-model:value="map.date_created"
                  :disabled="isLoading"
              />
              <span class="text-danger" v-if="isInvalid('date_created')">
                {{ invalidMessages('date_created')[0] }}
              </span>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item
                :required="true"
                label="作成者">
              <a-input
                  v-model:value="map.creator"
                  :disabled="isLoading"
                  :class="[isInvalid('creator') ? 'border-danger' : '']"
              />
              <span class="text-danger" v-if="isInvalid('creator')">
                {{ invalidMessages('creator')[0] }}
              </span>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item label="摘要">
              <a-textarea
                  :disabled="isLoading"
                  v-model:value="map.note"
              />
            </a-form-item>
            <span class="text-danger" v-if="isInvalid('note')">
                            {{ invalidMessages('note')[0] }}
                        </span>
          </a-col>
        </a-col>

        <a-col class="px-3" :span="12">
          <a-col :span="24">
            <a-form-item
                label="ファイル添付">
              <input type="file" ref="upload_file" @change="selectFile" hidden="hidden" multiple="multiple"/>
              <a-button :disabled="isLoading" @click="$refs.upload_file.click()">
                <UploadOutlined/>
                Upload
              </a-button>
            </a-form-item>
            <div class="offset-2 ps-2em">
              <li v-for="(item, index) in listFile">
                <PaperClipOutlined class="attack-file"/>
                {{ item }}
              </li>
            </div>
            <span class="text-danger" v-if="isInvalid('files')">
                            {{ invalidMessages('files')[0] }}
                        </span>
          </a-col>
        </a-col>
      </a-row>

      <div class="text-center">
        <a-button class="mx-2" :loading="isLoading" @click="$router.push({ name: 'map.list' })"
                  danger>キャンセル
        </a-button>
        <a-button class="mx-2" type="primary" :loading="isLoading" @click="create">登録</a-button>
      </div>

    </a-form>
  </div>
</template>

<script>
import {PaperClipOutlined, UploadOutlined} from "@ant-design/icons-vue";
import {FacilityModel} from "@/model/facility";
import {CompanyModel} from "@/model/company";
import {BuildingModel} from "@/model/building";
import {agency} from "@/model/agency";
import mapApi from '@/model/map';
import {message} from "ant-design-vue";

export default {
  components: {
    UploadOutlined,
    PaperClipOutlined,
  },
  data() {
    return {
      files: null,
      isLoading: false,
      labelCol: {span: 5},
      listFile: [],
      listDrawing: [],
      drawingType: null,
      listFacility: [],
      buildings: [],
      facility: null,
      map: {
        agency_id: null,
        company_id: null,
        facility_id: null,
        building_id: null,
        drawing_type_id: null,
        code: null,
        storage_location: null,
        date_created: null,
        creator: null,
        note: null,
        drawing_type: null
      },
      companies: [],
      agencies: [],
    }
  },
  methods: {
    async getListFacility() {
      this.loading = true
      let user = await this.$store.dispatch('auth/me')

      if (this.$store.state.role.role !== '管理者') {
        this.listFacility = [...await FacilityModel.getFacilityByParent(user.agency_id, user.company_id)]
      } else {
        this.listFacility = [...await FacilityModel.getAllFacilityNames()]
      }
    },

    async getListDrawingType() {
      this.loading = true
      this.listDrawing = [...await mapApi.listDrawingType()]
    },
    selectFile(e) {
      this.files = e.target.files
      this.listFile = []

      for (let i = 0; i < this.files.length; i++) {
        let file = this.files[i];
        this.listFile.push(file?.name)
      }
    },

      create() {
      this.clearError()
      this.isLoading = true
       mapApi.create(this.map, this.files).then(res => {
        if (res?.response?.status === 422) {
          this.tryGetErrorResponse(res)
        } else {
          if (res.success) {
            message.success(res.message)
          } else {
              if (res.response.status === 413){
                  message.error("1ファイルあたりの最大アップロードサイズは50MBまでです。")
                  this.isLoading = false
              }else {
                  message.error(res.message)
              }
          }
          this.$router.push({name: 'map.list'})
        }
        this.isLoading = false
      }).catch(error => {
           if (error.response && error.response.status === 413) {
               message.error("1ファイルあたりの最大アップロードサイズは50MBまでです。")
           }
       })
    },

    async filterCompanyByAgency() {
      this.map.company_id = null
      this.map.facility_id = null
      this.companies = [...await CompanyModel.getCompanyByAgency(this.map.agency_id ?? 0)]
      this.listFacility = [...await FacilityModel.getFacilityByParent(this.map.agency_id ?? 0, this.map.company_id ?? 0)]
    },

    async filterSelectFacilityByCompany() {
      this.map.facility_id = null
      this.listFacility = [...await FacilityModel.getFacilityByParent(this.map.agency_id ?? 0, this.map.company_id ?? 0)]
    },

    async filterSelectBuilding() {
      this.map.building_id = null
      this.buildings = await BuildingModel.getListBuilding({
          agency_id: this.map.agency_id ?? 0,
          company_id: this.map.company_id ?? 0,
          facility_id: this.map.facility_id ?? 0
      })
    },
  },
  async mounted() {
    let user = await this.$store.dispatch('auth/me')

    if (this.$store.state.role.role === '管理者') {
      this.agencies = await agency.getAllAgencyNames()
      this.companies = await CompanyModel.getAllCompanyNames()
      this.listFacility = await FacilityModel.getAllFacilityNames()
    } else {
      this.map.agency_id = user.agency_id
      this.map.company_id = user.company_id
      this.map.facility_id = user.facility_id
      this.companies = await CompanyModel.getCompanyByAgency(user.agency_id)
      this.listFacility = await FacilityModel.getFacilityByParent(user.agency_id, user.company_id)
    }

      this.buildings = [...await BuildingModel.getListBuilding({
          agency_id: this.map.agency_id ?? 0,
          company_id: this.map.company_id ?? 0,
          facility_id: this.map.facility_id ?? 0
      })]

    this.getListDrawingType();
  },

}
</script>
