<template>
  <a-layout class="layout" style="min-height: 100vh;">
    <a-layout-header style="background-color: #fff;">
      <div class="logo">
          <div style=" cursor: pointer;display: flex;align-items: center;gap: 0.5em;" @click="landingPage">
              <img src="../../../images/logo.svg" alt="Logo">
              <span>省エネ笑太くん</span>
          </div>
      </div>
    </a-layout-header>
    <a-layout-content class="p-4">
      <router-view></router-view>
    </a-layout-content>
    <a-layout-footer class="mt-auto bottom-0 w-100">
      <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-4">
          <div class="text-center mb-3" v-if="this.$route.name !== 'register'">本システムは令和２年度第３次補正
            事業再構築補助金により製作
          </div>
          <div class="text-center mb-3">Copyright©2023 SYK Co.,LTD</div>
        </div>
        <div class="col-md-4">
          <div class="text-center text-md-end mb-3">
            <a href="https://s-y-k.jp/" class="me-3" target="_blank"><u>sykホームページへ</u></a>
          </div>
        </div>
      </div>
    </a-layout-footer>
  </a-layout>
</template>

<script>
export default {
  methods: {
    landingPage() {
      window.open('https://showtakun.com/', '_blank');
    },
  },
}
</script>
