<template>
  <div class="row" v-if="this.roles_admin.includes(role)">
    <HomeAdmin/>
  </div>
  <div class="row company" v-if="this.roles_company.includes(role)">
    <HomeCompany/>
  </div>
  <div class="row" v-if="this.roles_facility.includes(role)">
    <HomeFacility/>
  </div>
</template>
<style scoped>
.ant-radio-group-outline {
  display: none;
}
</style>
<script>
import HomeAdmin from "../home/HomeAdmin.vue";
import HomeCompany from "../home/HomeCompany.vue";
import HomeFacility from "../home/HomeFacility.vue";

export default {
  components: {HomeAdmin, HomeCompany, HomeFacility},
  data() {
    return {
      roles_admin: [
        "管理者",
        "代理店管理者",
        "代理店担当者"
      ],
      roles_company: [
        "企業管理者",
        "企業担当者",
      ],
      roles_facility: [
        "施設担当者"
      ],
      role: ""
    }
  },
  mounted() {
    this.role = this.$store.state.role.role
  },
}

</script>
