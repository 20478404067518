<template>
  <div class="d-flex justify-content-between">
    <div class="screen-title">設備一覧</div>
    <a-button type="primary" size="large" ghost @click="$router.push({ name: 'equipment.create' })">
      新規作成
    </a-button>
  </div>
  <div class="content">
    <a-form>
      <a-row :gutter="24">
        <a-col :span="6">
          <a-form-item>
            <a-input
                v-model:value="searchParams.searchKey"
                placeholder="フリーテキスト検索"
                :disabled="isLoading"
                @keyup.enter="getList(1, {...searchParams})"
            />
          </a-form-item>
        </a-col>

        <a-col :span="18">
          <a-form-item label="次メンテ期間">
            <a-date-picker
                v-model:value="searchParams.next_maintenance_period_from"
                :value-format="dateFormat"
                class="me-4 w-40"/>
            ~
            <a-date-picker
                v-model:value="searchParams.next_maintenance_period_to"
                :value-format="dateFormat"
                class="mx-4 w-40"/>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row :gutter="24" class="position-relative">
        <a-col :span="6" v-if="this.$store.state.role.role === '管理者'">
          <a-form-item label="代理店">
            <a-select
                v-model:value="searchParams.agency"
                :disabled="isLoading"
                @keyup.enter="getList(1, {...searchParams})"
                :fieldNames="{label: 'name', value: 'id'}"
                :options="agencies"
                show-search
                :filter-option="filterOption"
                @change="filterCompanyByAgency"/>
          </a-form-item>
        </a-col>

        <a-col :span="6"
               v-if="!this.$store.state.role.role.includes('企業') && !this.$store.state.role.role.includes('施設')">
          <a-form-item label="企業">
            <a-select
                v-model:value="searchParams.company"
                :disabled="isLoading"
                @keyup.enter="getList(1, {...searchParams})"
                :fieldNames="{label: 'name', value: 'id'}"
                :options="companies"
                show-search
                :filter-option="filterOption"
                @change="filterSelectFacilityByCompany"/>
          </a-form-item>
        </a-col>

        <a-col :span="6" v-if="!this.$store.state.role.role.includes('施設')">
          <a-form-item label="施設">
            <a-select
                v-model:value="searchParams.facility"
                :disabled="isLoading"
                @keyup.enter="getList(1, {...searchParams})"
                :fieldNames="{label: 'name', value: 'id'}"
                :options="facilities"
                show-search
                :filter-option="filterOption"
                @change="filterSelectBuilding"/>
          </a-form-item>
        </a-col>

        <a-col :span="6">
          <a-form-item label="建屋">
            <a-select
                v-model:value="searchParams.building"
                :disabled="isLoading"
                @keyup.enter="getList(1, {...searchParams})"
                :fieldNames="{label: 'name', value: 'id'}"
                show-search
                :filter-option="filterOption"
                :options="buildings"/>
          </a-form-item>
        </a-col>

        <a-col :span="6">
          <a-form-item label="区分">
            <a-tree-select
                v-model:value="searchParams.equipment_type_id"
                :tree-data="treeData"
                tree-checkable
                placeholder="区分"
                @keyup.enter="getList(1, {...searchParams})"
                :loading="isLoading"/>
          </a-form-item>
        </a-col>

        <a-col :span="10">
          <a-form-item label="導入区分">
            <a-radio-group @keyup.enter="getList(1, {...searchParams})"
                           v-model:value="searchParams.contract_type">
              <a-radio value="">全て</a-radio>
              <a-radio value="1">購入</a-radio>
              <a-radio value="2">リース</a-radio>
            </a-radio-group>
          </a-form-item>
        </a-col>

        <a-col :span="4" class="text-end search-btn" style="bottom: 25px; right: 100px">
          <a-button type="primary" ghost @click="exportList(1, {...searchParams})" download="booking-report.csv'">
            <template #icon>
              <VerticalAlignBottomOutlined/>
            </template>
            出力
          </a-button>
        </a-col>

        <a-col :span="4" class="text-end search-btn" style="bottom: 25px">
          <a-button type="primary" :loading="isLoading" @click="getList(1, {...searchParams})">
            <template #icon>
              <SearchOutlined/>
            </template>
            検索
          </a-button>
        </a-col>
      </a-row>
    </a-form>

    <a-table
        :scroll="{ x: 2000 }"
        :loading="isLoading"
        :dataSource="dataSource"
        :columns="tableColumns"
        :pagination="pagination"
        @change="handleTableChange">
      <template #bodyCell="{column, text, record}">
        <template v-if="column.dataIndex === 'equipment_type' && record.equipment_type">
            {{ record?.equipment_type?.name }}
        </template>
        <template v-if="column.dataIndex === 'facility_name' && record.facility_id">
          <router-link :to="{ name: 'facility.edit', params: {facilityId: record?.facility_id }}">
            {{ record?.facility?.name }}
          </router-link>
        </template>
        <template v-if="column.dataIndex === 'building_name' && record.building_id">
          <router-link :to="{ name: 'building.edit', params: {buildingId: record?.building_id }}">
            {{ record?.building?.name }}
          </router-link>
        </template>
          <template v-if="column.dataIndex === 'next_alarm_date' && record.maintain_history">
            {{ record?.maintain_history?.next_alarm_date }}
          </template>
        <template v-if="column.dataIndex === 'action'">
          <router-link :to="{ name: 'equipment.edit', params: {equipmentId: record.id }}">詳細</router-link>
        </template>
      </template>
    </a-table>
  </div>
</template>

<script>
import {
  SearchOutlined, VerticalAlignBottomOutlined
} from '@ant-design/icons-vue'
import {CompanyModel} from "@/model/company"
import {FacilityModel} from "@/model/facility"
import {BuildingModel} from "@/model/building"
import {agency} from "@/model/agency";
import {MasterModel} from "@/model/master";
import {EquipmentModel} from "@/model/equipment";
import encoding from "encoding-japanese";
import dayjs from "dayjs";
import axios from "axios";

const defaultSelectOption = {
  name: '全て',
  id: null
}
export default {
  components: {
    SearchOutlined, VerticalAlignBottomOutlined

  },
  data() {
    const handleTableChange = (pag, filters, sorter) => {
      this.isLoading = true
      this.getList(pag.current, {...this.searchParams}, pag.pageSize)
      this.isLoading = false
    };

    return {
      isLoading: true,
      handleTableChange,
      dataSource: [],
      pagination: {},
      dateFormat: 'YYYY/MM/DD',
      searchParams: {
        searchKey: null,
        agency: null,
        company: null,
        facility: null,
        building: null,
        contract_type: '',
        next_maintenance_period_from: null,
        next_maintenance_period_to: null,
        equipment_type_id: []
      },
      agencies: [],
      companies: [],
      facilities: [],
      buildings: [],
      treeData: [],
      tableColumns: [
        {
          title: '設備名称',
          dataIndex: 'name',
          ellipsis: true
        },
        {
          title: '区分',
          dataIndex: 'equipment_type',
          ellipsis: true
        },
        {
          title: '台数',
          dataIndex: 'model_number',
          ellipsis: true
        },
        {
          title: '設置施設',
          dataIndex: 'facility_name',
          ellipsis: true
        },
        {
          title: '設置建屋',
          dataIndex: 'building_name',
          ellipsis: true
        },
        {
          title: '導入区分',
          dataIndex: 'contract_type_text',
          ellipsis: true
        },
        {
          title: 'メーカ',
          dataIndex: 'maker',
          ellipsis: true
        },
        {
          title: '型番',
          dataIndex: 'model',
          ellipsis: true
        },
        {
          title: '設置日',
          dataIndex: 'installation_date'
        },
        {
          title: '次アラーム日',
          dataIndex: 'next_alarm_date'
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          width: 100,
        }
      ]
    }
  },
  async mounted() {
    this.treeData = await MasterModel.getDivisionTreeView()

    let user = await this.$store.dispatch('auth/me')

    if (this.$store.state.role.role === '管理者') {
      this.agencies = [defaultSelectOption, ...await agency.getAllAgencyNames()]
      this.companies = [defaultSelectOption, ...await CompanyModel.getAllCompanyNames()]
      this.facilities = [defaultSelectOption, ...await FacilityModel.getAllFacilityNames()]
    } else {
      this.searchParams.agency = user.agency_id
      this.searchParams.company = user.company_id
      this.searchParams.facility = user.facility_id
      this.companies = [defaultSelectOption, ...await CompanyModel.getCompanyByAgency(user.agency_id ?? 0)]
      this.facilities = [defaultSelectOption, ...await FacilityModel.getFacilityByParent(user.agency_id ?? 0, user.company_id ?? 0)]
    }

    this.buildings = [defaultSelectOption, ...await BuildingModel.getListBuilding({
      agency_id: this.searchParams.agency ?? 0,
      company_id: this.searchParams.company ?? 0,
      facility_id: this.searchParams.facility ?? 0
    })]

    this.getList(1, this.searchParams)
  },
  methods: {
    getList(page, params = {
      searchKey: null,
      agency: null,
      company: null,
      facility: null,
      building: null,
      contract_type: '',
      next_maintenance_period_from: null,
      next_maintenance_period_to: null,
      equipment_type_id: null
    }, perPage = 10) {
      this.isLoading = true
      let searchParams = {page, ...params, perPage}
      EquipmentModel.getListEquipment(searchParams)
          .then(res => {
            this.dataSource = res.data
            this.pagination = {
              total: res.total,
              current: res.current_page,
              pageSize: res.per_page,
            }
            this.isLoading = false
          })
      return true
    },

    async filterCompanyByAgency() {
      this.searchParams.company = null
      this.searchParams.facility = null
      this.searchParams.building = null
      this.companies = [defaultSelectOption, ...await CompanyModel.getCompanyByAgency(this.searchParams.agency ?? 0)]
      this.facilities = [defaultSelectOption, ...await FacilityModel.getFacilityByParent(this.searchParams.agency ?? 0, this.searchParams.company ?? 0)]
      this.buildings = [defaultSelectOption, ...await BuildingModel.getListBuilding({
        agency_id: this.searchParams.agency ?? 0,
        company_id: this.searchParams.company ?? 0,
        facility_id: this.searchParams.facility ?? 0
      })]
    },

    async filterSelectFacilityByCompany() {
      this.searchParams.facility = null
      this.searchParams.building = null
      this.facilities = [defaultSelectOption, ...await FacilityModel.getFacilityByParent(this.searchParams.agency ?? 0, this.searchParams.company ?? 0)]
      this.buildings = [defaultSelectOption, ...await BuildingModel.getListBuilding({
        agency_id: this.searchParams.agency ?? 0,
        company_id: this.searchParams.company ?? 0,
        facility_id: this.searchParams.facility ?? 0
      })]
    },

    async filterSelectBuilding() {
      this.searchParams.building = null
      this.buildings = [defaultSelectOption, ...await BuildingModel.getListBuilding({
        agency_id: this.searchParams.agency ?? 0,
        company_id: this.searchParams.company ?? 0,
        facility_id: this.searchParams.facility ?? 0
      })]
    },

    async exportList(page, params = {
      searchKey: null,
      agency: null,
      company: null,
      facility: null,
      building: null,
      contract_type: '',
      next_maintenance_period_from: null,
      next_maintenance_period_to: null,
      equipment_type_id: null
    }) {
      this.isLoading = true;
      let searchParams = {page, ...params}
      const token = JSON.parse(localStorage.getItem('user'))
      const response = await axios.get(
          "/api/equipment/export-list-equipment",
          {
            params: searchParams,
              responseType: 'blob',
            headers: {
              "Authorization": `Bearer` + token.access_token,
              'Content-Type': 'multipart/form-data'
            }
          }
      );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        const nameFile = `equipment_${dayjs().format('YYYYMMDD')}.xlsx`;
        link.setAttribute('download', nameFile);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

      this.isLoading = false;
    },

    filterOption(input, option) {
       try {
           return option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
       } catch {
           return false;
       }
    },

  }
}
</script>
