<template>
  <div class="px-3">
    <router-link :to="{ name: 'config_require'}">
      <arrow-left-outlined/>
    </router-link>
  </div>
  <div class="px-3 mt-3">
    <a-form>
      <a-row :gutter="24">
        <a-col :span="6">
          <a-form-item>
            <a-input
                placeholder="フリーテキスト検索"
                v-model:value="searchParams.searchKey"
                @keyup.enter="this.getList(1, {...searchParams})"
                :disabled="isLoading"
            />
          </a-form-item>
        </a-col>

        <a-col :span="6">
          <a-form-item label="上場区分">
            <a-select
                v-model:value="searchParams.listType"
                :options="listDivision"
                :disabled="isLoading"
                @keyup.enter="this.getList(1, {...searchParams})"/>
          </a-form-item>
        </a-col>

        <a-col :span="6" class="text-end">
          <a-button type="primary" :loading="isLoading" @click="this.getList(1, {...searchParams})">
            <template #icon>
              <SearchOutlined/>
            </template>
            検索
          </a-button>
        </a-col>

      </a-row>
    </a-form>

    <a-table
        :loading="isLoading"
        :dataSource="dataSource"
        :columns="tableColumns"
        :pagination="pagination"
        @change="handleTableChange">
      <template #bodyCell="{column, text, record}">
        <template v-if="column.dataIndex === 'action'">
          <router-link :to="{ name: 'config_require.edit', params: {agencyId: agencyId, companyId: record.id}}">詳細
          </router-link>
        </template>
      </template>
    </a-table>
  </div>
</template>
<script>
import {
  ArrowLeftOutlined, SearchOutlined, UserAddOutlined
} from '@ant-design/icons-vue';
import {CompanyModel} from "@/model/company";

export default {
  components: {
    UserAddOutlined,
    SearchOutlined,
    ArrowLeftOutlined
  },
  data() {
    const handleTableChange = async (pag, filters, sorter) => {
      this.isLoading = true
      await this.getList(pag.current, {...this.searchParams})
      this.isLoading = false
    };

    return {
      isLoading: true,
      handleTableChange,
      tableColumns: [
        {
          title: '企業名称',
          dataIndex: 'name',
          key: 'name'
        },
        {
          title: '操作',
          dataIndex: 'action'
        }
      ],
      dataSource: [],
      pagination: {},
      searchParams: {
        searchKey: null,
        agencyId: null,
        listType: null
      },
      listDivision: [
        {
          label: '全て',
          value: null
        },
        {
          label: '上場',
          value: 1
        },
        {
          label: '非上場',
          value: 0
        }
      ],
      listAgency: [],
      agencyId: this.$router.currentRoute.value.params.agencyId
    }
  },
  async mounted() {
    this.getList(1, this.searchParams)
  },
  methods: {
    getList(page, params = {
      searchKey: null,
      agencyId: null,
      listType: null
    }) {
      this.isLoading = true
      let agencyId = this.$router.currentRoute.value.params.agencyId
      CompanyModel.list({
        page,
        searchKey: params.searchKey,
        agencyId: agencyId ? agencyId : params.agencyId,
        listType: params.listType
      })
          .then(res => {
            this.dataSource = res?.data
            this.pagination = {
              total: res?.total,
              current: res?.current_page,
              pageSize: res?.per_page,
            }
            this.isLoading = false
          })
    },
  }
}
</script>
