<template>
  <div class="d-flex justify-content-center my-5">
    <div class="card w-100" style="max-width: 1000px;">
      <div class="card-body p-5">
        <h3 class="text-center mb-4 fw-bold">省エネ笑太くん退会手続きが完了しました</h3>
        <div class="mb-3 text-center">
          退会手続き完了のメールをお送りしましたので、内容をご確認ください。<br>
          今まで省エネ笑太くんをご利用いただき、誠にありがとうございました。<br>
          なお、登録時に承認いただいたメールマガジンにつきましては、引き続き配信されます。<br><br>
          またのご利用を心よりお待ち申し上げております。
        </div>
      </div>
      <div class="mb-3 text-center">
        <a-button
            class="mx-2"
            type="primary"
            @click="$router.push({ name: 'login' })">
          ログイン画面へ
        </a-button>
      </div>
    </div>
  </div>
</template>
<script setup>
</script>