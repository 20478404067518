<template>
  <div class="px-3">
    <a-form layout="vertical">
      <a-row :gutter="24">
        <a-col :span="6">
          <a-input v-model:value="keyword" placeholder="フリーテキスト検索" @keyup.enter="doSearch"/>
        </a-col>
        <a-col :span="6">
          <a-button type="primary" :loading="isLoading" @click="doSearch">
            <template #icon>
              <SearchOutlined/>
            </template>
            検索
          </a-button>
        </a-col>
      </a-row>
    </a-form>
    <a-divider/>
    <a-table :dataSource="agencies.data"
             :columns="tableColumns"
             :pagination="pagination"
             :loading="isLoading"
             @change="onChange">
      <template #action="{ text, record }">
        <router-link :to="{ name: 'config_require_company', params: {agencyId: record.id }}">詳細</router-link>
      </template>
    </a-table>
  </div>
</template>
<script>
import {
  UserAddOutlined,
  SearchOutlined
} from '@ant-design/icons-vue';
import {agency} from "@/model/agency";
import {message} from 'ant-design-vue';

export default {
  components: {
    UserAddOutlined,
    SearchOutlined
  },
  data() {
    return {
      isLoading: false,
      tableColumns: [
        {
          title: '会社名',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: '都道府県',
          dataIndex: 'prefecture',
          key: 'prefecture',
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          slots: {title: 'action', customRender: 'action'},
        },

      ],
      agencies: {},
      pagination: {},
      keyword: ""
    };
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList(request = {"page": 1}) {
      this.isLoading = true
      agency.list(request).then(agencyList => {
        this.agencies = {
          data: agencyList.data,
          per_page: agencyList.per_page,
          current_page: agencyList.current_page,
          total: agencyList.total,
        }
        this.isLoading = false
      }).catch(errors => message.error('エラーが発生しました。しばらくしてからもう一度お試しください。'));
    },
    doSearch() {
      this.getList({'keyword': this.keyword})
    },

    onChange(pagination) {
      this.getList({"page": pagination.current})
    }
  },
}
</script>
