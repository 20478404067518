<template>
  <a-calendar id="calendar-dashboard" @select="onSelect" v-model:value="value">
    <template #dateCellRender="{ current }">
      <ul class="events">
        <li v-for="item in getListData(current)" :key="item.content">
          <a-tooltip>
            <template #title>
              <p>アラーム日: {{ item.next_alarm_date }}</p>
              <p>次メンテ日: {{ item.next_maintenance_date }}</p>
              <p>
                <router-link :to="{ name: 'equipment.edit', params: {equipmentId: item.id }}">設備名称:
                  {{ item.name }}
                </router-link>
              </p>
              <p>内容: {{ item.content }}</p>
            </template>
            <a-badge :status="item.type" :text="item.content"/>
          </a-tooltip>
        </li>
      </ul>
    </template>
    <template #headerRender="{ value: current, type, onChange, onTypeChange }">
      <div style="padding: 10px">
        <a-row type="flex" justify="end">
          <a-col>
            <a-radio-group size="small" :value="type" @change="e => onTypeChange(e.target.value)">
              <a-radio-button value="month">Month</a-radio-button>
              <a-radio-button value="year">Year</a-radio-button>
            </a-radio-group>
          </a-col>
          <a-col>
            <a-select
                size="small"
                :dropdown-match-select-width="false"
                class="my-year-select me-2"
                :value="String(current.year())"
                @change="newYear => {
                                    onChange(current.year(newYear));
                                }
                            "
            >
              <a-select-option
                  v-for="val in getYears(current)"
                  :key="String(val)"
                  class="year-item"
              >
                {{ val }}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col>
            <a-select
                size="small"
                :dropdown-match-select-width="false"
                :value="String(current.month())"
                @change="
                              selectedMonth => {
                                onChange(current.month(parseInt(selectedMonth, 10)));
                              }
                            "
            >
              <a-select-option
                  v-for="(val, index) in getMonths(current)"
                  :key="String(index)"
                  class="month-item"
              >
                {{ val }}
              </a-select-option>
            </a-select>
          </a-col>
        </a-row>
      </div>
    </template>
  </a-calendar>
</template>
<script>
import {defineComponent, ref} from 'vue';
import dayjs from "dayjs";
import {HomeModel} from "../../model/home";

export default defineComponent({
  props: ['role', 'facility'],
  data() {
    const value = ref();
    const getListData = value => {
      let listData = [];
      this.data.forEach((calendar) => {
        switch (true) {
          case value.date() == dayjs(calendar.next_alarm_date).format('DD'):
            listData.push({
              type: 'success',
              content: calendar.alarm_content,
              name: calendar.name,
              next_alarm_date: calendar.next_alarm_date,
              next_maintenance_date: calendar.next_maintenance_date,
              id: calendar.equipment_id
            })
            break;
          default:
        }
      })

      return listData || [];
    };

    const onPanelChange = (value, mode) => {
      console.log(value, mode);
    };

    const getMonths = value => {
      const localeData = value.localeData();
      const months = [];
      for (let i = 1; i <= 12; i++) {
        months.push(i);
      }
      return months;
    };

    const getYears = value => {
      const year = value.year();
      const years = [];
      for (let i = year - 10; i < year + 10; i += 1) {
        years.push(i);
      }
      return years;
    };

    return {
      value,
      getListData,
      isLoading: true,
      data: [],
      selectedMonth: dayjs().month() + 1,
      selectedYear: dayjs().year(),
      onPanelChange,
      getMonths,
      getYears,
    };
  },
  methods: {
    async getListAlarm(page = {
      month: null,
      year: null,
    }) {
      this.isLoading = true
      let user = await this.$store.dispatch('auth/me')
      await HomeModel.ListAlarm({
        month: this.selectedMonth,
        year: this.selectedYear,
        role: this.role,
        company_id: user.company_id,
        facility_id: this.facility
      }).then(res => {
        this.data = res
        this.isLoading = false
      })
    },
    async onSelect(date) {
      this.selectedMonth = date.month() + 1;
      this.selectedYear = date.year();
      await this.getListAlarm()
    },

  },
  mounted() {
    this.getListAlarm();
  }
});
</script>
<style>
#calendar-dashboard .events {
  list-style: none;
  margin: 0;
  padding: 0;
}

#calendar-dashboard .events .ant-badge-status {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 12px;
}

#calendar-dashboard .notes-month {
  text-align: center;
  font-size: 28px;
}

#calendar-dashboard .notes-month section {
  font-size: 28px;
}

#calendar-dashboard .year-item {
  width: 150px;
}

#calendar-dashboard .ant-select-selector {
  height: 33px !important;
  width: 83px !important;
}

#calendar-dashboard .ant-select-selector {
  height: 33px !important;
  width: 83px !important;
}

#calendar-dashboard .ant-select-selection-item {
  margin-top: 4px !important;
}

#calendar-dashboard .ant-select-arrow {
  margin-top: -7px !important;
  user-select: none !important;
}

#calendar-dashboard .ant-radio-group-outline {
  display: none;
}


</style>
