<template>
  <div class="px-3">
    <div class="screen-title">Config</div>
  </div>
  <div class="px-3">
    <a-row type="flex" justify="center" align="top">
      <a-col :span="6" class="config-box">
        <div class="config-content">
          <span class="title">facility</span>
          <div class="item">
            <a-checkbox v-model:checked="config.facility.name" :disabled="true">name</a-checkbox>
          </div>
          <div class="item">
            <a-checkbox v-model:checked="config.facility.address">address</a-checkbox>
          </div>
          <div class="item">
            <a-checkbox v-model:checked="config.facility.size_of_employee">size of employee</a-checkbox>
          </div>
          <div class="item">
            <a-checkbox v-model:checked="config.facility.concern">concern</a-checkbox>
          </div>
          <div class="item">
            <a-checkbox v-model:checked="config.facility.memo">memo</a-checkbox>
          </div>
          <div class="item">
            <a-checkbox v-model:checked="config.facility.contact_person_name">contact person</a-checkbox>
          </div>
          <div class="item">
            <a-checkbox v-model:checked="config.facility.phone">phone</a-checkbox>
          </div>
          <div class="item">
            <a-checkbox v-model:checked="config.facility.email">email</a-checkbox>
          </div>
          <div class="item">
            <a-checkbox v-model:checked="config.facility.job_title">job title</a-checkbox>
          </div>
        </div>
      </a-col>

      <a-col :span="6" class="config-box">
        <div class="config-content">
          <span class="title">building</span>
          <div class="item">
            <a-checkbox v-model:checked="config.building.name" :disabled="true">name</a-checkbox>
          </div>
          <div class="item">
            <a-checkbox v-model:checked="config.building.is_direct_profit">direct profit</a-checkbox>
          </div>
          <div class="item">
            <a-checkbox v-model:checked="config.building.floor_count" :disabled="true">floor count</a-checkbox>
          </div>
          <div class="item">
            <a-checkbox v-model:checked="config.building.building_type_id">building type</a-checkbox>
          </div>
          <div class="item">
            <a-checkbox v-model:checked="config.building.building_construction_type_id">building construction
            </a-checkbox>
          </div>
          <div class="item">
            <a-checkbox v-model:checked="config.building.build_area_square">build area square</a-checkbox>
          </div>
          <div class="item">
            <a-checkbox v-model:checked="config.building.employee_count">employee count</a-checkbox>
          </div>
          <div class="item">
            <a-checkbox v-model:checked="config.building.memo">memo</a-checkbox>
          </div>
        </div>
      </a-col>

      <a-col :span="6" class="config-box">
        <div class="config-content">
          <span class="title">contract</span>
          <div class="item">
            <a-checkbox v-model:checked="config.contract.agency_id" :disabled="true">agency</a-checkbox>
          </div>
          <div class="item">
            <a-checkbox v-model:checked="config.contract.company_id" :disabled="true">company</a-checkbox>
          </div>
          <div class="item">
            <a-checkbox v-model:checked="config.contract.facility_id">facility</a-checkbox>
          </div>
          <div class="item">
            <a-checkbox v-model:checked="config.contract.building_id">building</a-checkbox>
          </div>
          <div class="item">
            <a-checkbox v-model:checked="config.contract.energy_type_id" :disabled="true">energy type</a-checkbox>
          </div>
          <div class="item">
            <a-checkbox v-model:checked="config.contract.co2_convert_coefficient" :disabled="true">co2 convert
              coefficient
            </a-checkbox>
          </div>
          <div class="item">
            <a-checkbox v-model:checked="config.contract.contract_company_name">contract company</a-checkbox>
          </div>
          <div class="item">
            <a-checkbox v-model:checked="config.contract.term_start">term start</a-checkbox>
          </div>
          <div class="item">
            <a-checkbox v-model:checked="config.contract.term_end">term end</a-checkbox>
          </div>
          <div class="item">
            <a-checkbox v-model:checked="config.contract.electric_company_id" :disabled="true">electric company
            </a-checkbox>
          </div>
          <div class="item">
            <a-checkbox v-model:checked="config.contract.memo">memo</a-checkbox>
          </div>
        </div>
      </a-col>

      <a-col :span="6" class="config-box">
        <div class="config-content">
          <span class="title">equipment</span>
          <div class="item">
            <a-checkbox v-model:checked="config.equipment.agency_id" :disabled="true">agency</a-checkbox>
          </div>
          <div class="item">
            <a-checkbox v-model:checked="config.equipment.company_id" :disabled="true">company</a-checkbox>
          </div>
          <div class="item">
            <a-checkbox v-model:checked="config.equipment.facility_id">facility</a-checkbox>
          </div>
          <div class="item">
            <a-checkbox v-model:checked="config.equipment.building_id">building</a-checkbox>
          </div>
          <div class="item">
            <a-checkbox v-model:checked="config.equipment.name" :disabled="true">name</a-checkbox>
          </div>
          <div class="item">
            <a-checkbox v-model:checked="config.equipment.equipment_type_id" :disabled="true">equipment type
            </a-checkbox>
          </div>
          <div class="item">
            <a-checkbox v-model:checked="config.equipment.maker">maker</a-checkbox>
          </div>
          <div class="item">
            <a-checkbox v-model:checked="config.equipment.model">model</a-checkbox>
          </div>
          <div class="item">
            <a-checkbox v-model:checked="config.equipment.capacity">capacity</a-checkbox>
          </div>
          <div class="item">
            <a-checkbox v-model:checked="config.equipment.installation_detail_area">installation detail area
            </a-checkbox>
          </div>
          <div class="item">
            <a-checkbox v-model:checked="config.equipment.installation_date">installation date</a-checkbox>
          </div>
          <div class="item">
            <a-checkbox v-model:checked="config.equipment.contract_type">contract type</a-checkbox>
          </div>
          <div class="item">
            <a-checkbox v-model:checked="config.equipment.memo">memo</a-checkbox>
          </div>
          <div class="item">
            <a-checkbox v-model:checked="config.equipment.equipment_lease_company">equipment lease company</a-checkbox>
          </div>
          <div class="item">
            <a-checkbox v-model:checked="config.equipment.equipment_buy_company">equipment buy company</a-checkbox>
          </div>
          <div class="item">
            <a-checkbox v-model:checked="config.equipment.next_maintenance_period">next maintenance period</a-checkbox>
          </div>
          <div class="item">
            <a-checkbox v-model:checked="config.equipment.maintenance_time">maintenance time</a-checkbox>
          </div>
        </div>
      </a-col>

    </a-row>
    <div class="text-center">
      <a-button class="mx-2" type="primary" :loading="isLoading" @click="storeConfig">Save</a-button>
    </div>
  </div>
</template>
<script>
import {ConfigRequireModel} from "@/model/configRequire"
import {message} from "ant-design-vue";

export default {
  data() {
    return {
      isCheck: true,
      isLoading: false,
      config: {
        agency_id: null,
        company_id: null,
        facility: {
          name: true,
          address: false,
          size_of_employee: false,
          concern: false,
          memo: false,
          contact_person_name: false,
          phone: false,
          email: false,
          job_title: false
        },
        building: {
          name: true,
          is_direct_profit: false,
          floor_count: true,
          building_type_id: false,
          building_construction_type_id: false,
          build_area_square: false,
          area_square: false,
          employee_count: false,
          memo: false
        },
        contract: {
          agency_id: true,
          company_id: true,
          facility_id: false,
          building_id: false,
          energy_type_id: true,
          co2_convert_coefficient: true,
          contract_company_name: false,
          term_start: false,
          term_end: false,
          electric_company_id: true,
          memo: false
        },
        equipment: {
          agency_id: true,
          company_id: true,
          facility_id: false,
          building_id: false,
          name: true,
          equipment_type_id: true,
          maker: false,
          model: false,
          capacity: false,
          installation_detail_area: false,
          installation_date: false,
          contract_type: false,
          memo: false,
          equipment_lease_company: false,
          equipment_buy_company: false,
          next_maintenance_period: false,
          maintenance_time: false
        }
      }
    }
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    getDetail() {
      let agencyId = this.$router.currentRoute.value.params.agencyId
      let companyId = this.$router.currentRoute.value.params.companyId
      ConfigRequireModel.getCompanyDetail(agencyId, companyId)
          .then(res => {
            if (res.success) {
              this.config = res.data
            } else {
              this.config.agency_id = agencyId
              this.config.company_id = companyId
            }
          })
          .catch(err => {
            this.$router.push({name: 'config_require_company', params: {agencyId: agencyId}})
            message.error(err)
          })
    },

    storeConfig() {
      this.isLoading = true
      ConfigRequireModel.storeConfig(this.config)
          .then(res => {
            if (res?.response?.status === 422) {
            } else {
              if (res.success) {
                message.success(res.message)
              } else {
                message.error(res.message)
              }
            }
            this.isLoading = false
          })
    }
  }
}
</script>

<style scoped>
.config-box {
  .config-content {
    margin: 0 10px 20px 10px;
    background: #f3f3f3;
    border-radius: 5px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    padding: 5px 10px;

    .title {
      font-weight: bold;
      font-size: 20px;
    }
  }
}
</style>
