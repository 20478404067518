<template>
    <a-layout-header class="bg-light p-0 border-bottom d-flex justify-content-between">
        <div class="d-flex">
            <div style=" font-size: 20px; padding-left: 30px">{{ this.nameCompany }}</div>
        </div>
        <div class="d-flex">
            <div style="margin-right: 100px; font-size: 20px">
                {{ this.currYear }}年{{ this.currMonth }}月{{ this.currDay }}日<span
                class="ms-3">{{ this.currDD }}</span>
            </div>
            <Info/>
        </div>
    </a-layout-header>
    <a-layout-content class="p-4 detail-company">
        <div class="content">
            <div class="pt-1 d-flex justify-content-end">
                <a-form-item>
                    <a-date-picker v-model:value="this.dateFrom" @change="getDate" picker="month" class="me-2"/>
                    ~
                    <a-date-picker disabled v-model:value="this.dateTo" picker="month" class="ms-2"/>
                </a-form-item>
            </div>
            <a-form-item>
                <ul class="nav">
                    <li class="nav-item">
                        <a class="nav-link" style="color: #212529"
                           v-bind:class="{ active: (isActiveCo2 === activeId), 'disabled-link':isLoading }"
                           v-on:click="selectItem(0)"
                           aria-current="page" href="#">CO2</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="color: #212529"
                           v-bind:class="{ active: (isActiveMoney === activeId), 'disabled-link':isLoading }"
                           v-on:click="selectItem(-1, '料金')"
                           aria-current="page" href="#">料金</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="color: #212529"
                           v-bind:class="{ active: (isRecycledEnergy === activeId), 'disabled-link':isLoading }"
                           v-on:click="selectItem(-2, '料金(創エネ)')"
                           aria-current="page" href="#">料金(創エネ)</a>
                    </li>
                    <li class="nav-item" v-for="item in this.dataEnergyType ">
                        <a class="nav-link" style="color: #212529"
                           v-bind:class="{ active: (item?.id === activeId), 'disabled-link':isLoading }"
                           v-on:click="selectItem(item?.id, item.name)"
                           aria-current="page" href="#">{{ item.name }}</a>
                    </li>
                </ul>
            </a-form-item>
            <a-divider style="background-color: #d9d9d9"/>
              <div class="text-center fw-bold"><h3>{{ graphName }}</h3></div>
            <div class="d-flex justify-content-between">
                <div class=" fs-8 ms-3 mt-3 d-flex justify-content-between" style="width: 300px;">
                    <div style="color:#5B8FF9; margin-top: -3px">
                        <minus-outlined/>
                    </div>
                    <div class="ms-3">選択期間</div>
                    <div class="ms-3" style="color:#5AD8A6; margin-top: -3px">
                        <minus-outlined/>
                    </div>
                    <div class="ms-3">1年前</div>
                    <div class="ms-3" style="color:#5D7092; margin-top: -3px">
                        <minus-outlined/>
                    </div>
                    <span class="ms-3">2年前</span>
                </div>
                <a-form-item class="pt-3">
                    <a-checkbox v-model:checked="params.twoYear" @click="dataTwoYear">2年前</a-checkbox>
                    <a-checkbox v-model:checked="params.oneYear" @click="dataOneYear">1年前</a-checkbox>
                </a-form-item>
            </div>

            <div class="ms-5 unit">
                <p v-if="activeId == 0">tCO2</p>
                <p v-else-if="activeId == -1">円</p>
                <p v-else-if="activeId == -2">円</p>
                <p v-else>{{unit}}</p>
            </div>

            <div v-if="!isLoading" class="chart-container">
                <Responsive class="w-full">
                    <template #main="{ width }">
                        <Chart :size="{ width, height: 420 }" class="  line-chart-company"
                               :data="data"
                               :margin="margin"
                               :direction="direction"
                               :axis="axis"
                        >

                            <template #layers>
                                <Grid strokeDasharray="2,3"/>
                                <Line :dataKeys="['date', 'amountYear']" :lineStyle="{ stroke: '#5B8FF9' }"/>
                                <Line :dataKeys="['date', 'amountYearAgo']"
                                      :lineStyle="{ stroke: '#5AD8A6' }"/>
                                <Line :dataKeys="['date', 'amountTwoYearAgo']"
                                      :lineStyle="{ stroke: '#5D7092' }"/>
                            </template>

                            <template #widgets>
                                <Tooltip
                                    borderColor="red"
                                    :config="{
                          name: { hide: true },
                          date:{label: '日付',color: 'pink'},
                          amountYear: { label: '選択期間', color: '#5B8FF9',
                          format: (value) => value.toLocaleString('ja-JP', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                          })},
                          amountYearAgo: { label: '1年前', color: '#5AD8A6',
                             format: (value) => value.toLocaleString('ja-JP', {
                                 minimumFractionDigits: 2,
                                 maximumFractionDigits: 2
                             })},
                          amountTwoYearAgo: { label: '2年前', color: '#5D7092',
                            format: (value) => value.toLocaleString('ja-JP', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })},

                          inc: { hide: true }
                        }"
                                />
                            </template>
                        </Chart>
                    </template>
                </Responsive>
            </div>
            <div class="loading" v-else style="min-height: 380px">
                <a-spin/>
            </div>
        </div>
    </a-layout-content>
</template>

<script>
import {defineComponent, ref} from 'vue'
import {Chart, Grid, Line, Tooltip, Responsive} from 'vue3-charts'
import {DownOutlined, MinusOutlined} from "@ant-design/icons-vue";
import {EnergyContractModel} from "@/model/energyContract";
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import {HomeModel} from "@/model/home";
import Info from './header/Info.vue'
import {hide} from "@popperjs/core";
import {mapGetters} from "vuex";

export default defineComponent({
    name: 'LineChart',
    components: {
        MinusOutlined,
        DownOutlined,
        Chart,
        Grid,
        Line,
        Tooltip,
        Responsive,
        EnergyContractModel,
        HomeModel,
        Info
    },
    setup() {
        const direction = ref('horizontal')
        const margin = ref({
            left: 50,
            top: 20,
            right: 20,
            bottom: 10
        })

        return {
            direction, margin,
            unit: ref(null),
            data: ref([]),
            dataEnergyType: ref([]),
            isLoading: ref(false),
            currMonth: dayjs().format('MM'),
            currYear: dayjs().format('YYYY'),
            currDay: dayjs().format('DD'),
            currDD: dayjs().locale('ja').format('dddd'),
            nameCompany: ref(null),
            dateFrom: ref(null),
            dateTo: ref(null),
            dateFormat: 'YYYY/MM',
            isActiveCo2: ref(0),
            isActiveMoney: ref(-1),
            isRecycledEnergy: ref(-2),
            activeId: ref(0),
            checkYear: ref(true),
            params: ref({
                date: null,
                activeId: 0,
                oneYear: ref(true),
                twoYear: ref(true),
            }),
            axis: ref({
                secondary: {
                    domain: [],
                    type: 'linear',
                    ticks: 8
                }
            }),
            graphName : 'CO2比較'
        }
    },

    methods: {
        async getDate() {
            this.isLoading = true
            const dateFrom = new Date(this.dateFrom?.format('YYYY/MM'));
            const date = new Date(dateFrom.setMonth(dateFrom?.getMonth() + 11));
            this.dateTo = dayjs(date.getFullYear() + '/' + (date.getMonth() + 1));
            this.params.date = this.dateTo.format('YYYY/MM');

            HomeModel.ListDetailCompany(this.params)
                .then(res => {
                    this.data = res.data
                    this.unit = res.unit
                    this.nameCompany = res.nameCompany;
                    this.axis.secondary.domain = [0, 'dataMax'];
                    this.isLoading = false
                })
        },
        async energyType() {
            EnergyContractModel.getListEnergyType().then(res => {
                this.dataEnergyType = res;
            });
        },
        async selectItem(id, graphName = 'CO2') {
            this.activeId = id
            this.params.activeId = id
            this.graphName = graphName + "比較"
            this.getDate();
        },
        async dataOneYear() {
            this.params.oneYear = !this.params.oneYear;
            await this.getDate()
        },
        async dataTwoYear() {
            this.params.twoYear = !this.params.twoYear;
            await this.getDate()
        }

    },
    async mounted() {
        const currentYear = dayjs().year();
        const formattedDate = `${currentYear}-01`;
        this.energyType();
        this.dateFrom = dayjs(formattedDate);
        const dateFrom = new Date(this.dateFrom?.format('YYYY/MM'));
        const date = new Date(dateFrom.setMonth(dateFrom?.getMonth() + 11));
        this.dateTo = dayjs(date.getFullYear() + '/' + (date.getMonth() + 1))
        this.params.date = this.dateTo.format('YYYY/MM');
        await this.getDate();
    },
})
</script>
<style>

.detail-company.ant-layout {
    margin-top: 10px;
}

.detail-company .ant-select-item-option-content {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.detail-company .active {
    color: #1890ff !important;
}

.detail-company ul li {
    font-size: 15px;
}

.line-chart-company .v-tooltip-line-vertical {
    display: none;
}

.detail-company .loading {
    text-align: center;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 30px 50px;
    margin: 20px 0;
}

.detail-company .disabled-link {
    pointer-events: none;
}

.detail-company .unit p{
    margin-bottom: -5px;
}
</style>
