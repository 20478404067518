import AlarmList from "@/views/alarm/List.vue";
import store from "@/stores/_loader";

const accessRole = store.state.accessRole.buildingRole

export const AlarmRoutes = [
    {
        path: "/alarm/list",
        name: "alarm.list",
        component: AlarmList,
        meta: {
            menuKey: "alarm",
            accessRole
        },
    },
];
