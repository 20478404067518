<template>
  <div class="d-flex justify-content-center my-5">
    <div class="card w-100" style="max-width: 500px;">
      <div class="card-body p-5 text-center">
        <h4>仮登録が完了いたしました</h4>
        <div class="mb-5"></div>
        <p>登録したメールアドレスに、本登録のご案内が<br>届いているのでご確認ください。</p>
        <div class="mb-5"></div>
        <a-collapse v-model:activeKey="activeKey" accordion :bordered="false"
                    @change="collapseHandle"
                    style="background: rgb(255, 255, 255)">
          <a-collapse-panel key="1" class="custom-style" :show-arrow="false">
            <template #header>
              <div class="position-relative fw-bold">
                メールが届かない場合
                <caret-right-outlined class="custom-arrow" :style="rotate"/>
              </div>
            </template>
            <p>
              しばらくたってもご案内メールが見当たらない場合は、迷惑メールフォルダなどに振り分けられているか、間違ったアドレスでお手続きをされた可能性があります。</p>
            <p>その場合は、お手数ですが、
              <router-link :to="({ name: 'register' })">こちら</router-link>
              から再度ご登録をお願いします。
            </p>
          </a-collapse-panel>
        </a-collapse>
        <a-button type="primary" html-type="button" @click="goToLogin">
          ログイン画面へ
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import {CaretRightOutlined} from "@ant-design/icons-vue";

export default {
  components: {CaretRightOutlined},
  data() {
    return {
      activeKey: [],
      rotate: null
    }
  },
  mounted() {
    if (this.$store.getters['auth/isLogged']) {
      this.$router.push({name: 'home'});
      return;
    }
  },
  methods: {
    goToLogin() {
      this.$router.push({name: 'login'});
    },

    collapseHandle(key) {
      if (key !== undefined) {
        this.rotate = 'transform: rotate(90deg);'
      } else {
        this.rotate = null
      }
    }
  },
}
</script>

<style scoped lang="scss">
.custom-style {
  background: #f7f7f7;
  border-radius: 4px;
  margin-bottom: 24px;
  overflow: hidden;
  border: none !important;
  text-align: left;
}

.custom-arrow {
  position: absolute;
  bottom: 5px;
  transition: transform 0.24s;
}
</style>
