import {authApi} from "./authApi";

const path = '/api/config-require';

export const ConfigRequireModel = {

    /**
     *
     * @param agencyId
     * @param companyId
     * @returns {Promise<*|undefined>}
     */
    getCompanyDetail(agencyId, companyId)
    {
        return authApi.get(`${path}/${agencyId}/${companyId}/detail`)
    },

    /**
     *
     * @param params
     * @returns {Promise<*>}
     */
    storeConfig(params)
    {
        return authApi.post(`${path}/store`, params, false)
    }
}
