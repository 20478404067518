<template>
  <div class="d-flex justify-content-between">
    <div class="screen-title">アラーム一覧</div>
  </div>
  <div>
    <div class="content">
      <a-form>
        <a-row :gutter="24" class="position-relative">
          <a-col :span="10" :xxl="8">
            <a-form-item
                label="アラーム期間"
            >
              <a-date-picker
                  :value-format="dateFormat"
                  v-model:value="time_from"
                  class="me-4 w-40"
              />
              ~
              <a-date-picker
                  :value-format="dateFormat"
                  class="ms-4 w-40"
                  v-model:value="time_to"
              />
            </a-form-item>
          </a-col>

          <a-col :span="2">
            <a-button class="btn-time" type="default" :loading="isLoading" @click="fillThisMonth">
              今月
            </a-button>
          </a-col>
          <a-col :span="2">
            <a-button class="btn-time" type="default" :loading="isLoading" @click="fillNextMonth">
              来月
            </a-button>
          </a-col>
          <a-col :span="4" class="text-end search-btn">
            <a-button type="primary" :loading="isLoading" @click="getList(1)">
              <template #icon>
                <SearchOutlined/>
              </template>
              検索
            </a-button>
          </a-col>
        </a-row>
      </a-form>
      <a-divider/>

      <a-table
          :loading="isLoading"
          :dataSource="dataSource"
          :columns="tableColumns"
          :pagination="pagination"
          @change="handleTableChange">
        <template #bodyCell="{column, text, record}">
          <template v-if="column.dataIndex === 'alert_date'">
            {{ dayjs(record.next_alarm_date).format('YYYY-MM-DD') }}
          </template>
          <template v-if="column.dataIndex === 'equipment_name'">
            {{ record?.equipment?.name }}
          </template>
          <template v-if="column.dataIndex === 'previous_maintenance'">
            {{ record?.content }}
          </template>
            <template v-if="column.dataIndex === 'facility_name'">
                {{ record?.equipment?.facility?.name }}
            </template>
            <template v-if="column.dataIndex === 'building_name'">
                {{ record?.equipment?.building?.name }}
            </template>
          <template v-if="column.dataIndex === 'location'">
            {{ record?.equipment?.installation_detail_area }}
          </template>
            <template v-if="column.dataIndex === 'contract_type' && record?.contract_type == 1">
                購入
            </template>
            <template v-if="column.dataIndex === 'contract_type' && record?.contract_type == 2">
                リース
            </template>
          <template v-if="column.dataIndex === 'company'">
            {{ record?.maintain_company?.name }}
          </template>
          <template v-if="column.dataIndex === 'next_maintain_day'">
            {{ dayjs(record.next_maintenance_date).format('YYYY-MM-DD') }}
          </template>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>

import {defineComponent} from "vue";
import {SearchOutlined, UserAddOutlined} from "@ant-design/icons-vue";
import dayjs from 'dayjs';
import {AlarmModel} from "@/model/alarm";

export default {
  components: {
    SearchOutlined
  },
  data() {
    const handleTableChange = async (pag, filters, sorter) => {
      this.isLoading = true
      await this.getList(pag.current, {...this.search}, pag.pageSize)
      this.isLoading = false
    };
    return {
      isLoading: false,
      dataSource: [],
      time_from: null,
      time_to: null,
      handleTableChange,
      dateFormat: 'YYYY/MM/DD',
      tableColumns: [
        {
          title: 'アラーム日',
          dataIndex: 'alert_date',
          key: 'alert_date',
        },
        {
          title: '設備名称',
          dataIndex: 'equipment_name',
          key: 'equipment_name',
          ellipsis: true
        },
        {
          title: '前のメンテ内容',
          dataIndex: 'previous_maintenance',
          key: 'previous_maintenance',
        },
        {
          title: '施設名',
          dataIndex: 'facility_name',
          key: 'facility_name',
          ellipsis: true
        },
        {
          title: '建屋名',
          dataIndex: 'building_name',
          key: 'building_name',
          ellipsis: true
        },
        {
          title: '場所',
          dataIndex: 'location',
          key: 'location',
        },
        {
          title: '購入・リース',
          dataIndex: 'contract_type',
          key: 'contract_type',
        },
        {
          title: 'メンテ会社',
          dataIndex: 'company',
          key: 'company',
        },
        {
          title: 'メンテ履歴',
          dataIndex: 'next_maintain_day',
          key: 'next_maintain_day',
        },
      ],
      pagination: {},
    }
  },
  computed: {
    dayjs() {
      return dayjs
    }
  },
  methods: {
    async getList(page, search = {
      time_start: null,
      time_end: null,
    }, perPage = 10) {
      this.isLoading = true
      let user = await this.$store.dispatch('auth/me')
      let role = this.$store.state.role.role === '管理者'
      AlarmModel.list({
        page,
        perPage,
        time_from: this.time_from,
        time_to: this.time_to,
        agency_id: role ? 0 : user.agency_id,
        company_id: role ? 0 : user.company_id,
        facility_id: role ? 0 : user.facility_id
      }).then(res => {
        this.dataSource = res
        this.pagination = {
          total: res?.total,
          current: res?.current_page,
          pageSize: res?.per_page,
        }
        this.isLoading = false
      })
    },

    fillThisMonth() {
      let firstDayOfMonth = dayjs().startOf('month').toDate();
      firstDayOfMonth = dayjs(firstDayOfMonth).format('YYYY/MM/DD');
      let lastDayOfMonth = dayjs().endOf('month').toDate();
      lastDayOfMonth = dayjs(lastDayOfMonth).format('YYYY/MM/DD');

      this.time_from = firstDayOfMonth;
      this.time_to = lastDayOfMonth;
    },

    fillNextMonth() {
      let firstDayOfNextMonth = dayjs().add(1, 'month').startOf('month').toDate();
      firstDayOfNextMonth = dayjs(firstDayOfNextMonth).format('YYYY/MM/DD');
      let lastDayOfNextMonth = dayjs().add(1, 'month').endOf('month').toDate();
      lastDayOfNextMonth = dayjs(lastDayOfNextMonth).format('YYYY/MM/DD');

      this.time_from = firstDayOfNextMonth;
      this.time_to = lastDayOfNextMonth;
    },
  },
  mounted() {
    this.getList()
  }
}
</script>

<style>
.btn-time {
  background-color: #f8f9fa;
  border: 1px solid #bfbfbf;
}

.btn-time:hover {
  cursor: pointer;
}
</style>
