<template>
  <div class="d-flex justify-content-between">
    <div class="screen-title">使用状況詳細</div>
  </div>
  <div class="content">
    <a-form :label-col="labelCol">
      <a-row :gutter="24">
        <a-col class="px-3" :span="12">

          <a-col :span="24">
            <a-form-item
                label="エネルギー種類"
                :rules="[{ required:true}]">
              <a-select
                  placeholder="エネルギー種類"
                  v-model:value="this.usage.energy_type_id"
                  :loading="isLoading"
                  :fieldNames="{label: 'name', value: 'id'}"
                  @change="handleChangeContractParams"
                  :options="energyTypes"/>
            </a-form-item>
          </a-col>

          <a-col :span="24" v-if="energyName === '創エネ'">
            <a-form-item
                label="創エネ種類"
                :rules="[{ required:true}]">
              <a-select
                  placeholder="創エネ種類"
                  v-model:value="usage.detail_type"
                  :loading="isLoading"
                  :fieldNames="{label: 'name', value: 'id'}"
                  :options="detailTypeList"
                  show-search/>
              <span class="text-danger" v-if="isInvalid('detail_type')">
                                {{ invalidMessages('detail_type')[0] }}
                            </span>
            </a-form-item>
          </a-col>

          <a-col :span="24" v-if="this.$store.state.role.role === '管理者'">
            <a-form-item
                :rules="[{ required:true}]"
                label="代理店">
              <a-select
                  v-model:value="usage.agency_id"
                  placeholder="代理店"
                  :loading="isLoading"
                  :fieldNames="{ label: 'name', value: 'id' }"
                  @change="filterCompanyByAgency"
                  :options="agencies"/>
              <span class="text-danger" v-if="isInvalid('agency_id')">
                                {{ invalidMessages('agency_id')[0] }}
                            </span>
            </a-form-item>
          </a-col>

          <a-col :span="24"
                 v-if="!this.$store.state.role.role.includes('企業') && !this.$store.state.role.role.includes('施設')">
            <a-form-item
                label="企業"
                :rules="[{ required:true}]">
              <a-select
                  placeholder="企業"
                  :options="companies"
                  :loading="isLoading"
                  :fieldNames="{label: 'name', value: 'id'}"
                  @change="filterSelectFacilityByCompany"
                  v-model:value="usage.company_id"/>
            </a-form-item>
          </a-col>

          <a-col :span="24" v-if="!this.$store.state.role.role.includes('施設')">
            <a-form-item
                label="施設">
              <a-select
                  placeholder="施設"
                  :options="facilities"
                  :loading="isLoading"
                  :fieldNames="{label: 'name', value: 'id'}"
                  @change="filterSelectBuilding"
                  v-model:value="usage.facility_id"/>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item
                label="建屋">
              <a-select
                  placeholder="建屋"
                  :options="buildings"
                  :loading="isLoading"
                  :fieldNames="{label: 'name', value: 'id'}"
                  v-model:value="usage.building_id"
              />
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item
                label="CO2変換係数">
              <a-input :readonly="true" v-model:value="usage.co2_convert_coefficient"/>
            </a-form-item>
          </a-col>


        </a-col>

        <a-col class="px-3" :span="12">

          <a-col :span="24">
            <a-form-item
                :rules="[{ required:true}]">
              <template #label v-if="energyName === '創エネ'">期間</template>
              <template #label v-else>使用期間</template>
              <a-date-picker
                  :format="dateFormat"
                  :loading="isLoading"
                  class="me-4 w-40"
                  v-model:value="usage.usage_from_date"
                  />
              ~
              <a-date-picker
                  :format="dateFormat"
                  :loading="isLoading"
                  class="me-4 w-40"
                  v-model:value="usage.usage_to_date"
                  :class="[isInvalid('usage_to_date') ? 'border-danger' : '']"/>
              <br>
              <span class="text-danger" v-if="isInvalid('usage_to_date')">
                {{ invalidMessages('usage_to_date')[0] }}
              </span>
              <span class="text-danger" v-if="isInvalid('usage_from_date')">
                {{ invalidMessages('usage_from_date')[0] }}
              </span>
            </a-form-item>
          </a-col>

          <a-col :span="24" v-if="energyName !== '創エネ'">
            <a-form-item label="請求日">
              <a-date-picker
                  placeholder="請求日"
                  class="w-40"
                  :disabled="checkHaveContract"
                  :value-format="dateFormat"
                  v-model:value="usage.billing_date"
                  :class="[isInvalid('billing_date') ? 'border-danger' : '']"/>
              <span class="text-danger" v-if="isInvalid('billing_date')">
                                {{ invalidMessages('billing_date')[0] }}
                            </span>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item label="支払い日">
              <a-date-picker
                  placeholder="支払い日"
                  class="w-40"
                  :loading="isLoading"
                  :format="dateFormat"
                  v-model:value="usage.invoice_date"
                  :class="[isInvalid('invoice_date') ? 'border-danger' : '']"/>
              <span class="text-danger" v-if="isInvalid('invoice_date')">
                                {{ invalidMessages('invoice_date')[0] }}
                            </span>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item
                :rules="[{ required:true}]">
              <template #label v-if="energyName === '創エネ'">発電量</template>
              <template #label v-else>使用量</template>
              <a-input
                  class="w-25"
                  placeholder="発電量"
                  :loading="isLoading"
                  @change="handleChangeUsedAmount"
                  v-model:value="usage.amount"
                  :class="[isInvalid('amount') ? 'border-danger' : '']"/>
              {{ energyUnit }}
              <span class="text-danger" v-if="isInvalid('amount')">
                                {{ invalidMessages('amount')[0] }}
                            </span>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item
                :rules="[{ required:true}]">
              <template #label v-if="energyName === '創エネ'">金額</template>
              <template #label v-else>総額</template>
              <a-input
                  class="w-25"
                  placeholder="金額:"
                  :loading="isLoading"
                  v-model:value="money"
                  @change="handleMoney"
                  :class="[isInvalid('money') ? 'border-danger' : '']"/>&ensp;円
              <span class="text-danger" v-if="isInvalid('money')">
                                {{ invalidMessages('money')[0] }}
                            </span>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item
                label="ファイル添付">
              <input type="file" ref="upload_file" @change="uploadFile" hidden="hidden"
                     multiple="multiple"/>
              <a-button :disabled="isLoading" class="w-25" @click="$refs.upload_file.click()">
                <UploadOutlined/>
                Upload
              </a-button>
            </a-form-item>
            <div class="offset-2 ps-2em list-item">
              <li class="position-relative" v-for="(item, index) in fileList">
                <a-button :disabled="isLoading" type="link" primary
                          @click="downloadFile(item.path, item.name)">
                  <PaperClipOutlined class="attack-file"/>
                  {{ item.name }}
                </a-button>
                <a-button :disabled="isLoading" class="delete-file-btn position-absolute end-0 d-none"
                          @click="deleteFile(item.path)" type="link" primary>
                  <delete-outlined/>
                </a-button>
              </li>
            </div>
          </a-col>

        </a-col>
      </a-row>

      <div class="text-center">
        <a-button class="mx-2" :loading="isLoading" @click="$router.push({ name: 'usage.list' })" danger>キャンセル
        </a-button>
        <a-button class="mx-2" type="primary" :loading="isLoading" @click="this.visible = true" danger>削除
        </a-button>
        <a-button class="mx-2" type="primary" :loading="isLoading" @click="updateUsage">更新</a-button>
      </div>

    </a-form>

    <a-modal
        v-model:visible="visible"
        title="操作確認"
        ok-text="はい"
        cancel-text="いいえ"
        @ok="deleteUsage"
        @cancel="this.visible = false"
        :bodyStyle="{ borderRadius: '5px' }"
        :width="400"
        :okButtonProps="{ type: 'danger' }"
    >
      <p>
        <ExclamationCircleOutlined class="larger-icon"/>
        削除してもよろしいでしょうか?
      </p>
    </a-modal>
  </div>
</template>

<script>
import {
  UploadOutlined,
  PaperClipOutlined,
  DeleteOutlined, ExclamationCircleOutlined
} from '@ant-design/icons-vue'
import dayjs from "dayjs";
import {CompanyModel} from "@/model/company"
import {FacilityModel} from "@/model/facility"
import {BuildingModel} from "@/model/building"
import {EnergyContractModel} from "@/model/energyContract";
import {UsageModel} from "@/model/usage";
import {message} from "ant-design-vue";
import {StorageModel} from "@/model/storage";
import {agency} from "@/model/agency";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault("UTC")

export default {
  components: {
    ExclamationCircleOutlined,
    UploadOutlined,
    PaperClipOutlined,
    DeleteOutlined
  },
  data() {
    return {
      isLoading: true,
      visible: false,
      checkHaveContract: true,
      labelCol: {span: 5},
      dateFormat: 'YYYY/MM/DD',
      energyTypes: [],
      agencies: [],
      companies: [],
      facilities: [],
      buildings: [],
      energy_type_id: null,
      usage: {},
      fileList: [],
      usedAmount: 0,
      energyUnit: null,
      detailTypeList: [
        {
          id: 1,
          name: '太陽光'
        },
        {
          id: 2,
          name: '風力'
        },
        {
          id: 3,
          name: '地熱'
        },
        {
          id: 4,
          name: 'その他'
        }
      ],
      energyName: null,
      money: null
    }
  },
  async mounted() {
    let user = await this.$store.dispatch('auth/me');
    [
      this.energyTypes,
      this.agencies,
      this.companies,
      this.facilities,
      this.buildings
    ] = await Promise.all([
      [...await EnergyContractModel.getListEnergyType()],
      [...await agency.getAllAgencyNames()],
      [...await CompanyModel.getAllCompanyNames()],
      [...await FacilityModel.getAllFacilityNames()],
      [...await BuildingModel.getListBuilding({
        agency_id: 0,
        company_id: 0,
        facility_id: 0
      })]
    ])

    if (this.$store.state.role.role === '管理者') {
      this.agencies = await agency.getAllAgencyNames()
      this.companies = await CompanyModel.getAllCompanyNames()
      this.facilities = [...await FacilityModel.getAllFacilityNames()]
    } else {
      this.usage.agency_id = user.agency_id
      this.usage.company_id = user.company_id
      this.usage.facility_id = user.facility_id
      this.companies = await CompanyModel.getCompanyByAgency(user.agency_id ?? 0)
      this.facilities = [...await FacilityModel.getFacilityByParent(user.agency_id ?? 0, user.company_id ?? 0)]
    }

    this.buildings = [...await BuildingModel.getListBuilding({
      agency_id: this.usage.agency_id ?? 0,
      company_id: this.usage.company_id ?? 0,
      facility_id: this.usage.facility_id ?? 0
    })]

    this.getDetail()
  },
  methods: {
    updateUsage() {
      this.clearError()
      this.isLoading = true
      if (this.energyName === '創エネ') {
        this.usage.money = Math.abs(this.usage.money) * (-1)
      } else {
        this.usage.money = Math.abs(this.usage.money)
      }
      UsageModel.update(this.usage.id, this.usage)
          .then(res => {
            if (res?.response?.status === 422) {
              this.tryGetErrorResponse(res)
            } else {
              message.success(res.message)
              this.$router.push({name: 'usage.list'})
            }
            this.isLoading = false
          })
    },

    async getDetail() {
      let user = await this.$store.dispatch('auth/me')
      let usageId = this.$router.currentRoute.value.params.usageId
      UsageModel.getDetail(usageId)
          .then(async resData => {
            let fileList = []
            if (resData?.data?.files) {
              let files = resData?.data?.files
              files.map(val => {
                fileList.push({
                  name: val.name,
                  path: val.path
                })
              })
            }
            this.fileList = fileList

            if (resData && resData.success === false) {
              message.error(resData.message)
              this.$router.push({name: 'usage.list'})
            } else {
              let checkRole = await this.$store.dispatch('role/checkRole', {
                user: user,
                data: resData.data
              })
              if (this.$store.state.role.role === '管理者' || checkRole) {
                this.usage = resData.data
                this.energy_type_id = resData.data.contract?.energy_type?.id
                this.usage.usage_from_date = dayjs.tz(`${this.usage.usage_from_date}`, 'utc')
                this.usage.usage_to_date = dayjs.tz(`${this.usage.usage_to_date}`, 'utc')
                // this.usage.billing_date = dayjs(`${this.usage.billing_date}`)
                this.usage.invoice_date = this.usage.invoice_date ? dayjs.tz(`${this.usage.invoice_date}`, 'utc') : null;

                this.energyTypes.map(val => {
                  if (val.id === this.usage.energy_type_id) {
                    this.energyUnit = val.unit
                    this.energyName = val.name
                  }
                })
                this.money = Math.abs(this.usage.money)
              } else {
                message.error(`アクセス権限がありません。`)
                this.$router.push({name: 'usage.list'})
              }
            }
            this.isLoading = false
          })
    },

    handleChangeUsedAmount(event) {
      this.usage.converted_co2_amount = this.usage.amount * this.usage.co2_convert_coefficient
    },

    handleMoney(event) {
      if (this.energyName !== '創エネ') {
        this.usage.money = event.target.value
      } else {
        this.usage.money = -event.target.value
      }

      this.money = event.target.value
    },

    async downloadFile(link) {
      let usageId = this.$router.currentRoute.value.params.usageId
      let param = {
        path: link,
      }
      UsageModel.downloadFile(usageId, param)
          .then(res => {
            if (res.status) {
              let element = document.createElement('a');
              element.setAttribute('href', res.data.url);
              element.setAttribute('download', name);
              element.style.display = 'none';
              document.body.appendChild(element);
              element.click();
              document.body.removeChild(element);
            } else {
              message.error(res.message)
            }
          })
    },

    uploadFile(e) {
      this.isLoading = true
      let usageId = this.$router.currentRoute.value.params.usageId
      let params = {
        id: usageId,
      }
      let files = e.target.files
      UsageModel.uploadFile(params, files)
          .then(res => {
            if (res.status) {
              this.getDetail()
              message.success(res.message)
            } else {
                if (res.response.status === 413){
                    message.error("1ファイルあたりの最大アップロードサイズは50MBまでです。")
                    this.isLoading = false
                }else {
                    message.error(res.message)
                }
            }
          }).catch(error => {
              if (error.response && error.response.status === 413) {
                  message.error("1ファイルあたりの最大アップロードサイズは50MBまでです。")
              }
          })
    },

    deleteFile(path) {
      this.isLoading = true
      let usageId = this.$router.currentRoute.value.params.usageId
      let param = {
        path: path
      }
      UsageModel.deleteFile(usageId, param)
          .then(res => {
            if (res.status) {
              this.getDetail()
              message.success(res.message)
            } else {
              message.error(res.message)
            }
          })
    },

    deleteUsage() {
      this.isLoading = true
      UsageModel.deleteUsage(this.$router.currentRoute.value.params.usageId)
          .then(res => {
            if (res && res.status === true) {
              message.success('削除されました。')
              this.$router.push({name: 'usage.list'})
            } else {
              message.error('に削除されました。')
            }
            this.isLoading = false
          })
    },

    handleChangeContractParams() {
      this.energyTypes.map(val => {
        if (val.id === this.usage.energy_type_id) {
          this.energyUnit = val.unit
          this.energyName = val.name
        }
      })

      if (this.energyName !== '創エネ') {
        if (!this.checkHaveContract) {
          this.usage.co2_convert_coefficient = 0
          this.usage.converted_co2_amount = 0
          this.usage.energy_contract_id = null
          this.usage.agency_id = null
          this.checkHaveContract = true
        }
      } else {
        this.checkHaveContract = false
      }
    },

    async filterCompanyByAgency() {
      this.usage.company_id = null
      this.usage.facility_id = null
      this.usage.building_id = null
      this.companies = [...await CompanyModel.getCompanyByAgency(this.usage.agency_id ?? 0)]
      this.facilities = [...await FacilityModel.getFacilityByParent(this.usage.agency_id ?? 0, this.usage.company_id ?? 0)]
      this.buildings = [...await BuildingModel.getListBuilding({
        agency_id: this.usage.agency_id ?? 0,
        company_id: this.usage.company_id ?? 0,
        facility_id: this.usage.facility_id ?? 0
      })]
      this.handleChangeContractParams()
    },

    async filterSelectFacilityByCompany() {
      this.usage.facility_id = null
      this.usage.building_id = null
      this.facilities = [...await FacilityModel.getFacilityByParent(this.usage.agency_id ?? 0, this.usage.company_id ?? 0)]
      this.buildings = [...await BuildingModel.getListBuilding({
        agency_id: this.usage.agency_id ?? 0,
        company_id: this.usage.company_id ?? 0,
        facility_id: this.usage.facility_id ?? 0
      })]
      this.handleChangeContractParams()
    },

    async filterSelectBuilding() {
      this.usage.building_id = null
      this.buildings = [...await BuildingModel.getListBuilding({
        agency_id: this.usage.agency_id ?? 0,
        company_id: this.usage.company_id ?? 0,
        facility_id: this.usage.facility_id ?? 0
      })]
      this.handleChangeContractParams()
    },
  }
}
</script>

<style scoped lang="scss">
.list-item > li:hover {
  .delete-file-btn {
    display: inline-block !important;
  }
}
</style>
