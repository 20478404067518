import DetailHomeCompany from "@/views/home/DetailHomeCompany.vue";
import DetailHomeFacility from "@/views/home/DetailHomeFacility.vue";
import DetailCo2ByFacility from "@/views/home/DetailCo2ByFacility.vue";
import DetailCo2ByBuilding from "@/views/home/DetailCo2ByBuilding.vue";
import HomeFacility from "@/views/home/HomeFacility.vue";
import store from "@/stores/_loader";
import Home from "../views/home/Home.vue";

const accessRole = store.state.accessRole.companyRole
export const HomeRoutes = [
    {
        path: "/",
        name: "home",
        component: Home,
        meta: {
            menuKey: "home",
        },
    },
    {
        path: "/home/detail-company",
        name: "home.detail-company",
        component: DetailHomeCompany,
        meta: {
            menuKey: "home",
            accessRole
        },
    },
    {
        path: "/home/facility/:facilityId",
        name: "home.facility",
        component: HomeFacility,
        meta: {
            menuKey: "home",
            accessRole
        },
    },
    {
        path: "/home/detail-facility/:facilityId",
        name: "home.detail-facility",
        component: DetailHomeFacility,
        meta: {
            menuKey: "home",
        },
    },
    {
        path: "/home/detail-co2-by-facility",
        name: "home.detail-co2-by-facility",
        component: DetailCo2ByFacility,
        meta: {
            menuKey: "home",
        },
    },
    {
        path: "/home/detail-co2-by-building/:facilityId",
        name: "home.detail-co2-by-building",
        component: DetailCo2ByBuilding,
        meta: {
            menuKey: "home",
        },
    },
]
