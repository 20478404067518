<template>
  <div class="d-flex justify-content-between">
    <div class="screen-title">設備登録</div>
  </div>
  <div class="content">
    <a-form :label-col="labelCol">
      <a-row :gutter="24">
        <a-col class="px-3" :span="12">
          <a-col :span="24">
            <a-form-item
                label="設備名称"
                :required="true">
              <a-input
                  placeholder="設備名称"
                  v-model:value="equipment.name"
                  :class="[isInvalid('name') ? 'border-danger' : '']"/>
              <span class="text-danger" v-if="isInvalid('name')">
                                    {{ invalidMessages('name')[0] }}
                                </span>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item
                :required="true"
                label="区分">
              <a-tree-select
                  v-model:value="equipment.equipment_type_id"
                  :tree-data="treeData"
                  tree-default-expand-all
                  placeholder="区分"
                  :loading="isLoading"
                  :class="[isInvalid('equipment_type_id') ? 'border-danger' : '']"/>
              <span class="text-danger" v-if="isInvalid('equipment_type_id')">
                                    {{ invalidMessages('equipment_type_id')[0] }}
                                </span>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item
                label="メーカ">
              <a-input
                  v-model:value="equipment.maker"
                  placeholder="メーカ"
                  :class="[isInvalid('maker') ? 'border-danger' : '']"/>
              <span class="text-danger" v-if="isInvalid('maker')">
                                    {{ invalidMessages('maker')[0] }}
                                </span>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item
                label="型番">
              <a-input
                  v-model:value="equipment.model"
                  placeholder="型番"
                  :class="[isInvalid('model') ? 'border-danger' : '']"/>
              <span class="text-danger" v-if="isInvalid('model')">
                                {{ invalidMessages('model')[0] }}
                            </span>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item
                label="台数">
              <a-input
                  v-model:value="equipment.model_number"
                  placeholder="台数"
                  :class="[isInvalid('model_number') ? 'border-danger' : '']"/>
              <span class="text-danger" v-if="isInvalid('model_number')">
                                {{ invalidMessages('model_number')[0] }}
                            </span>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item
                label="定格(容量)">
              <a-input
                  v-model:value="equipment.capacity"
                  placeholder="定格(容量)"
                  :class="[isInvalid('capacity') ? 'border-danger' : '']"/>
              <span class="text-danger" v-if="isInvalid('capacity')">
                                    {{ invalidMessages('capacity')[0] }}
                                </span>
            </a-form-item>
          </a-col>

          <a-col :span="24" v-if="this.$store.state.role.role === '管理者'">
            <a-form-item
                :required="true"
                label="設置代理店">
              <a-select
                  v-model:value="equipment.agency_id"
                  :loading="isLoading"
                  placeholder="設置代理店"
                  :options="agencies"
                  :fieldNames="{label: 'name', value: 'id'}"
                  :class="[isInvalid('agency_id') ? 'border-danger' : '']"
                  @change="filterCompanyByAgency"/>
              <span class="text-danger" v-if="isInvalid('agency_id')">
                                {{ invalidMessages('agency_id')[0] }}
                            </span>
            </a-form-item>
          </a-col>

          <a-col :span="24"
                 v-if="!this.$store.state.role.role.includes('企業') && !this.$store.state.role.role.includes('施設')">
            <a-form-item
                :required="true"
                label="設置企業">
              <a-select
                  v-model:value="equipment.company_id"
                  :loading="isLoading"
                  placeholder="設置企業"
                  :options="companies"
                  :fieldNames="{label: 'name', value: 'id'}"
                  :class="[isInvalid('company_id') ? 'border-danger' : '']"
                  @change="filterSelectFacilityByCompany"/>
              <span class="text-danger" v-if="isInvalid('company_id')">
                                {{ invalidMessages('company_id')[0] }}
                            </span>
            </a-form-item>
          </a-col>

          <a-col :span="24" v-if="!this.$store.state.role.role.includes('施設')">
            <a-form-item
                label="設置施設">
              <a-select
                  v-model:value="equipment.facility_id"
                  :loading="isLoading"
                  placeholder="設置施設"
                  :options="facilities"
                  :fieldNames="{label: 'name', value: 'id'}"
                  :class="[isInvalid('facility_id') ? 'border-danger' : '']"
                  @change="filterSelectBuilding"/>
              <span class="text-danger" v-if="isInvalid('facility_id')">
                                {{ invalidMessages('facility_id')[0] }}
                            </span>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item
                label="設置建屋">
              <a-select
                  v-model:value="equipment.building_id"
                  :loading="isLoading"
                  placeholder="設置建屋"
                  :options="buildings"
                  :fieldNames="{label: 'name', value: 'id'}"
                  :class="[isInvalid('building_id') ? 'border-danger' : '']"/>
              <span class="text-danger" v-if="isInvalid('building_id')">
                                {{ invalidMessages('building_id')[0] }}
                            </span>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item
                label="場所">
              <a-input
                  v-model:value="equipment.installation_detail_area"
                  placeholder="場所"
                  :class="[isInvalid('installation_detail_area') ? 'border-danger' : '']"/>
              <span class="text-danger" v-if="isInvalid('installation_detail_area')">
                                    {{ invalidMessages('installation_detail_area')[0] }}
                                </span>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item
                label="設置日">
              <a-date-picker
                  v-model:value="equipment.installation_date"
                  :value-format="dateFormat"
                  class="me-4 w-40"
                  :class="[isInvalid('installation_date') ? 'border-danger' : '']"/>
              <span class="text-danger" v-if="isInvalid('installation_date')">
                                    {{ invalidMessages('installation_date')[0] }}
                                </span>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item
                label="点検スパン">
              <div class="d-flex flex-nowrap">
                <a-input
                    type="number"
                    v-model:value="equipment.maintenance_time"
                    @change="handleMaintenanceTime"
                    :class="[isInvalid('maintenance_time') ? 'border-danger' : '']"/>
                <span style="width: 40px; padding: 5px">ヶ月</span>
              </div>
              <span class="text-danger" v-if="isInvalid('maintenance_time')">
                                {{ invalidMessages('maintenance_time')[0] }}
                            </span>
            </a-form-item>
          </a-col>

        </a-col>

        <a-col class="px-3" :span="12">

          <a-col :span="24" class="d-flex ps-2em ms-4">
            <a-form-item
                class="w-100"
                label="施工/メンテ会社名" :label-col="{ span:8}">
              <a-select
                  v-model:value="equipment.maintain_company_id"
                  :fieldNames="{label: 'name', value: 'id'}"
                  :options="maintain_companies"
                  :class="[isInvalid('energy_type_id') ? 'border-danger' : '']"/>
            </a-form-item>
            <router-link :to="{name: 'maintain-company.create'}" target="_blank">
              <a-button class="ms-2" type="primary" :loading="isLoading">+</a-button>
            </router-link>
          </a-col>

          <a-col :span="24">
            <a-form-item label="導入区分" :label-col="{ span:8}">
              <a-radio-group v-model:value="equipment.contract_type">
                <a-radio value="1">購入</a-radio>
                <a-radio value="2">リース</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>

          <a-col :span="24" :hidden="equipment.contract_type !== '1'">
            <div class="ant-row ant-form-item" style="row-gap: 0px;">
              <div class="ant-col ant-col-5 ant-form-item-label">
                &ensp;
              </div>
              <a-col class="ant-form-item-control company-box pt-4">
                <a-form :label-col="{span: 4}">
                  <a-col :span="24">
                    <a-form-item
                        label="購入先">
                      <a-input
                          v-model:value="equipment.equipment_buy_company.retailer"
                          placeholder="購入先"/>
                    </a-form-item>
                  </a-col>

                  <a-col :span="24">
                    <a-form-item
                        label="購入金額">
                      <a-input
                          v-model:value="equipment.equipment_buy_company.amount"
                          placeholder="購入金額"/>
                    </a-form-item>
                  </a-col>

                  <a-col :span="24">
                    <a-form-item label="保証書">
                      <a-radio-group v-model:value="equipment.equipment_buy_company.warranty">
                        <a-radio value="1">あり</a-radio>
                        <a-radio value="2">なし</a-radio>
                      </a-radio-group>
                    </a-form-item>
                  </a-col>

                  <a-col :span="24">
                    <a-form-item
                        label="保証期間">
                      <a-input
                          v-model:value="equipment.equipment_buy_company.warranty_period"
                          placeholder="保証期間"/>
                    </a-form-item>
                  </a-col>

                </a-form>
              </a-col>
            </div>
          </a-col>

          <a-col :span="24" :hidden="equipment.contract_type !== '2'">
            <div class="ant-row ant-form-item" style="row-gap: 0px;">
              <div class="ant-col ant-col-5 ant-form-item-label">
                &ensp;
              </div>
              <a-col class="ant-form-item-control company-box pt-4">
                <a-form :label-col="{span: 5}">
                  <a-col :span="24">
                    <a-form-item
                        label="リース会社">
                      <a-input
                          v-model:value="equipment.equipment_lease_company.lease_company"
                          placeholder="リース会社"/>
                    </a-form-item>
                  </a-col>

                  <a-col :span="24">
                    <a-form-item
                        label="担当者">
                      <a-input
                          v-model:value="equipment.equipment_lease_company.contact_address"
                          placeholder="担当者"/>
                    </a-form-item>
                  </a-col>

                  <a-col :span="24">
                    <a-form-item
                        label="都道府県">
                      <a-input
                          v-model:value="equipment.equipment_lease_company.prefectures"
                          placeholder="都道府県"/>
                    </a-form-item>
                  </a-col>

                  <a-col :span="24">
                    <a-form-item
                        label="住所">
                      <a-input
                          v-model:value="equipment.equipment_lease_company.address"
                          placeholder="住所"/>
                    </a-form-item>
                  </a-col>

                  <a-col :span="24">
                    <a-form-item
                        label="電話番号">
                      <a-input
                          v-model:value="equipment.equipment_lease_company.phone"
                          placeholder="電話番号"/>
                    </a-form-item>
                  </a-col>

                  <a-col :span="24">
                    <a-form-item
                        label="契約期限">
                      <a-date-picker
                          v-model:value="equipment.equipment_lease_company.contract_period"
                          placeholder="契約期限"
                          :value-format="dateFormat"
                          class="me-4 w-40"/>
                    </a-form-item>
                  </a-col>

                  <a-col :span="24">
                    <a-form-item
                        label="契約金額">
                      <a-input
                          v-model:value="equipment.equipment_lease_company.amount"
                          placeholder="契約金額"/>
                    </a-form-item>
                  </a-col>

                </a-form>
              </a-col>
            </div>
          </a-col>

          <a-col :span="24">
            <a-form-item label="メモ" :label-col="{ span:8}">
              <a-textarea v-model:value="equipment.memo"
                          :class="[isInvalid('memo') ? 'border-danger' : '']"/>
              <span class="text-danger" v-if="isInvalid('memo')">
                                {{ invalidMessages('memo')[0] }}
                            </span>
            </a-form-item>
          </a-col>

        </a-col>
      </a-row>

      <div class="text-center">
        <a-button class="mx-2" @click="$router.push({ name: 'equipment.list' })" danger>キャンセル</a-button>
        <a-button class="mx-2" type="primary" :loading="isLoading" @click="create">登録</a-button>
      </div>
    </a-form>
  </div>
</template>

<script>
import {MasterModel} from "@/model/master";
import {CompanyModel} from "@/model/company";
import {FacilityModel} from "@/model/facility";
import {BuildingModel} from "@/model/building";
import {agency} from "@/model/agency";
import {EquipmentModel} from "@/model/equipment";
import {MaintainCompanyModel} from "@/model/maintain-company";
import dayjs from "dayjs";
import {message} from "ant-design-vue";

const currentDay = dayjs()
export default {
  data() {
    return {
      isLoading: true,
      labelCol: {span: 5},
      treeData: [],
      dateFormat: 'YYYY/MM/DD',
      agencies: [],
      companies: [],
      facilities: [],
      buildings: [],
      equipment: {
        name: '',
        equipment_type_id: null,
        maker: '',
        model: '',
        capacity: '',
        installation_detail_area: '',
        installation_date: currentDay,
        maintenance_time: 1,
        contract_type: '',
        memo: '',
        agency_id: null,
        company_id: null,
        facility_id: null,
        building_id: null,
        equipment_buy_company: {
          retailer: null,
          amount: null,
          warranty: null,
          warranty_period: null,
        },
        equipment_lease_company: {
          lease_company: null,
          contact_address: null,
          prefectures: null,
          address: null,
          phone: null,
          contract_period: currentDay,
          amount: null
        },
        maintain_company_id: null,
        model_number: null
      },
      maintain_companies: []
    }
  },
  async mounted() {
    this.treeData = await MasterModel.getDivisionTreeView()

    let user = await this.$store.dispatch('auth/me')
    if (this.$store.state.role.role === '管理者') {
      this.agencies = await agency.getAllAgencyNames()
      this.companies = await CompanyModel.getAllCompanyNames()
      this.facilities = [...await FacilityModel.getAllFacilityNames()]
    } else {
      this.equipment.agency_id = user.agency_id
      this.equipment.company_id = user.company_id
      this.equipment.facility_id = user.facility_id
      this.companies = await CompanyModel.getCompanyByAgency(user.agency_id ?? 0)
      this.facilities = [...await FacilityModel.getFacilityByParent(user.agency_id ?? 0, user.company_id ?? 0)]
    }

    this.buildings = [...await BuildingModel.getListBuilding({
      agency_id: this.equipment.agency_id ?? 0,
      company_id: this.equipment.company_id ?? 0,
      facility_id: this.equipment.facility_id ?? 0
    })]

    MaintainCompanyModel.getAllMaintainCompany()
        .then(res => {
          this.maintain_companies = res
        })

    this.isLoading = false
  },
  methods: {
    create() {
      this.isLoading = true
      this.equipment.installation_date = this.equipment.installation_date !== null ? dayjs(this.equipment.installation_date).format('YYYY-MM-DD') : null;
      this.equipment.equipment_lease_company.contract_period = this.equipment.equipment_lease_company.contract_period !== null ? dayjs(this.equipment.equipment_lease_company.contract_period).format('YYYY-MM-DD') : null;
      EquipmentModel.createEquipment(this.equipment)
          .then(res => {
            if (res?.response?.status === 422) {
              this.tryGetErrorResponse(res)
            } else {
              if (res.success) {
                message.success(res.message)
              } else {
                message.error(res.message)
              }
              this.$router.push({name: 'equipment.list'})
            }
            this.isLoading = false
          })
    },

    async filterCompanyByAgency() {
      this.equipment.company_id = null
      this.equipment.facility_id = null
      this.equipment.building_id = null
      this.companies = await CompanyModel.getCompanyByAgency(this.equipment.agency_id ?? 0)
      this.facilities = [...await FacilityModel.getFacilityByParent(this.equipment.agency_id ?? 0, this.equipment.company_id ?? 0)]
      this.buildings = [...await BuildingModel.getListBuilding({
        agency_id: this.equipment.agency_id ?? 0,
        company_id: this.equipment.company_id ?? 0,
        facility_id: this.equipment.facility_id ?? 0
      })]
    },

    async filterSelectFacilityByCompany() {
      this.equipment.facility_id = null
      this.equipment.building_id = null
      this.facilities = [...await FacilityModel.getFacilityByParent(this.equipment.agency_id ?? 0, this.equipment.company_id ?? 0)]
      this.buildings = [...await BuildingModel.getListBuilding({
        agency_id: this.equipment.agency_id ?? 0,
        company_id: this.equipment.company_id ?? 0,
        facility_id: this.equipment.facility_id ?? 0
      })]
    },

    async filterSelectBuilding() {
      this.equipment.building_id = null
      this.buildings = [...await BuildingModel.getListBuilding({
        agency_id: this.equipment.agency_id ?? 0,
        company_id: this.equipment.company_id ?? 0,
        facility_id: this.equipment.facility_id ?? 0
      })]
    },

    handleMaintenanceTime() {
      if (this.equipment.maintenance_time > 100) {
        this.equipment.maintenance_time = 100
      }
      if (this.equipment.maintenance_time < 1) {
        this.equipment.maintenance_time = 1
      }
    }
  }
}
</script>

<style scoped lang="scss">
.company-box {
  background: #D9D9D9;
  border-radius: 5px;
}
</style>
