<template>
  <a-layout>
    <a-layout-sider v-model:collapsed="collapsed" :trigger="null" collapsible style="min-height: 100vh;">
      <div class="logo" style="color: #fff; font-size: 1em; padding: 10px; cursor: pointer" @click="landingPage">
        <img src="../../images/logo.svg" alt="Logo">
        <span>省エネ笑太くん</span>
      </div>
      <a-menu :selectedKeys="this.$store.state.route.currentRoute" theme="dark" mode="inline">
        <a-menu-item key="home" @click="$router.push({ name: 'home' })">
          <dashboard-outlined/>
          <span>ダッシュボード</span>
        </a-menu-item>
        <a-menu-item key="usage" @click="$router.push({ name: 'usage.list' })"
                     v-if="buildingMenuRole.includes(this.$store.state.role.role)">
          <BarChartOutlined/>
          <span>使用状況管理</span>
        </a-menu-item>
        <a-menu-item key="user" @click="$router.push({ name: 'user.list' })"
                     v-if="userMenuRole.includes(this.$store.state.role.role)">
          <user-outlined/>
          <span>アカウント管理</span>
        </a-menu-item>
        <a-menu-item key="agencies" @click="$router.push({ name: 'agency.list' })"
                     v-if="agencyMenuRole.includes(this.$store.state.role.role)">
          <shop-outlined/>
          <span>代理店管理</span>
        </a-menu-item>
        <a-menu-item key="company" @click="$router.push({ name: 'company.list' })"
                     v-if="companyMenuRole.includes(this.$store.state.role.role)">
          <gold-outlined/>
          <span>企業管理</span>
        </a-menu-item>
        <a-menu-item key="facility" @click="$router.push({ name: 'facility.list' })"
                     v-if="facilityMenuRole.includes(this.$store.state.role.role)">
          <minus-square-outlined/>
          <span>施設管理</span>
        </a-menu-item>
        <a-menu-item key="building" @click="$router.push({ name: 'building.list' })"
                     v-if="buildingMenuRole.includes(this.$store.state.role.role)">
          <container-outlined/>
          <span>建屋管理</span>
        </a-menu-item>
        <a-menu-item key="energy_contract" @click="$router.push({ name: 'energy_contract.list' })"
                     v-if="buildingMenuRole.includes(this.$store.state.role.role)">
          <ThunderboltOutlined/>
          <span>エネルギー契約管理</span>
        </a-menu-item>
        <a-menu-item key="equipment" @click="$router.push({name: 'equipment.list'})"
                     v-if="buildingMenuRole.includes(this.$store.state.role.role)">
          <tool-outlined/>
          <span>設備管理</span>
        </a-menu-item>
        <a-menu-item key="maintain-company" @click="$router.push({name: 'maintain-company.list'})"
                     v-if="buildingMenuRole.includes(this.$store.state.role.role)">
          <api-outlined/>
          <span>施工・メンテ会社管理</span>
        </a-menu-item>
        <a-menu-item key="map_management" @click="$router.push({name: 'map.list'})"
                     v-if="buildingMenuRole.includes(this.$store.state.role.role)">
          <heat-map-outlined/>
          <span>図面管理</span>
        </a-menu-item>
        <a-menu-item key="alarm" @click="$router.push({name: 'alarm.list'})"
                     v-if="buildingMenuRole.includes(this.$store.state.role.role)">
          <bell-outlined/>
          <span>アラーム</span>
        </a-menu-item>
      </a-menu>
      <a-menu class="mt-auto" v-model:selectedKeys="this.$store.state.route.currentRoute" theme="dark"
              mode="inline">
        <a-menu-item key="manual" @click="manualLink">
           <reconciliation-outlined/>
           <span>マニュアル</span>
        </a-menu-item>
        <a-menu-item key="master" @click="$router.push({ name: 'master.list' })"
                     v-if="this.$store.state.role.role === '管理者'">
          <setting-outlined/>
          <span>マスター設定</span>
        </a-menu-item>
        <a-menu-item key="logout" :disabled="isLoggingOut" @click="doLogout">
          <loading-outlined v-if="isLoggingOut"/>
          <logout-outlined v-else/>
          <span>ログアウト</span>
        </a-menu-item>
      </a-menu>
    </a-layout-sider>
    <a-layout v-screen-height="'screen-height'">
      <a-layout-header v-if="this.$store.state.route.currentRoute != 'home'"
                       class="bg-light p-0 border-bottom d-flex justify-content-between">
        <div>
          <menu-unfold-outlined v-if="collapsed" class="menu-trigger"
                                @click="() => (collapsed = !collapsed)"/>
          <menu-fold-outlined v-else class="menu-trigger" @click="() => (collapsed = !collapsed)"/>
        </div>
        <Info/>
      </a-layout-header>
      <a-layout v-screen-height="'screen-height'" v-if="this.$store.state.route.currentRoute == 'home'">
        <router-view/>
      </a-layout>
      <a-layout-content v-if="this.$store.state.route.currentRoute != 'home'" class="p-4">
        <router-view/>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>


<script>
import {
  BulbOutlined,
  UserOutlined,
  DashboardOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  ShopOutlined,
  GoldOutlined,
  MinusSquareOutlined,
  SettingOutlined,
  LogoutOutlined,
  LoadingOutlined,
  ContainerOutlined,
  ThunderboltOutlined,
  DownOutlined,
  BarChartOutlined,
  ToolOutlined,
  ApiOutlined,
  HeatMapOutlined,
  BellOutlined,
  ReconciliationOutlined
} from '@ant-design/icons-vue';
import Auth from "./auth/Auth.vue";
import Info from "./home/header/Info.vue";

export default {
  components: {
    Auth,
    BulbOutlined,
    UserOutlined,
    DashboardOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    ShopOutlined,
    GoldOutlined,
    MinusSquareOutlined,
    SettingOutlined,
    LogoutOutlined,
    LoadingOutlined,
    ContainerOutlined,
    ThunderboltOutlined,
    DownOutlined,
    BarChartOutlined,
    ToolOutlined,
    ApiOutlined,
    HeatMapOutlined,
    BellOutlined,
    ReconciliationOutlined,
    Info
  },
  data() {
    return {
      collapsed: false,
      isLoggingOut: false,
      userMenuRole: this.$store.state.accessRole.userManagementRole,
      agencyMenuRole: this.$store.state.accessRole.adminRole,
      companyMenuRole: this.$store.state.accessRole.agencyRole,
      facilityMenuRole: this.$store.state.accessRole.facilityRole,
      buildingMenuRole: this.$store.state.accessRole.buildingRole,
      user_name: this.$store.getters["auth/getUser"].user_name
    };
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    landingPage() {
      window.open('https://showtakun.com/', '_blank');
    },
    async doLogout() {
      this.isLoggingOut = true;

      await this.$store.dispatch('auth/logout')
          .then(() => {
            this.$store.commit('auth/removeUser')
            this.$store.dispatch('role/removeRole')
            this.$router.push({name: 'login'});
          })

      this.isLoggingOut = false;
    },
    handleResize() {
      this.screenY = window.innerHeight;
    },
    manualLink() {
      window.open('https://showtakun.com/manual', '_blank');
    },
  },
}
</script>
<style>
.ant-select-item-option-content {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search-btn {
  position: absolute;
  right: 0;
}
</style>
