<template>
  <a-dropdown>
    <div class="header-user-info">
      <img
          src="https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/default-avatar.png"
          alt="">
      <span>{{ user_name }}</span>
      <DownOutlined/>
    </div>
    <template #overlay>
      <a-menu>
        <a-menu-item>
          <router-link :to="{ name: 'auth.changepass' }">パスワード変更</router-link>
        </a-menu-item>
        <a-menu-item v-if="role === '企業管理者'">
          <router-link :to="{ name: 'auth.delete-account' }">退会</router-link>
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
</template>

<script setup lang="ts">
import {DownOutlined} from "@ant-design/icons-vue";
import store from "@/stores/_loader";

const user_name = store.getters['auth/getUser'].username
const role = store.state.role.role
</script>
